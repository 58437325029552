/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { ICheckbox } from './../../../shared-ui-refresh/checkbox/contracts/checkbox.model'
import { filtersData } from './contracts/filter-container.model';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DividerOption } from './../../../shared-ui-refresh/divider/divider.model';
import { SidePanelOptions } from './../../../shared-ui-refresh/side-panel/constants/side-panel.model';
import { SidePanelService } from './../../../shared-ui-refresh/side-panel/services/side-panel.service';
import { IButtonData } from './../../../shared-ui-refresh/button/contracts/button-model';
import { IDropdown } from '../dropdown/dropdown-model';
import { IDropdownEventType } from './../../../shared-ui-refresh/dropdown/contracts/dropdown-model';
import { DatePipe } from '@angular/common';
@Component({
    selector: 'nalco-filter-container',
    templateUrl: './filter-container.component.html',
    styleUrls: ['./filter-container.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FilterContainerComponent implements OnInit {

    @Input() filterData: filtersData[];

    @Input() sidePanelOptions: SidePanelOptions;

    @Output() selectedFilterData?: EventEmitter<filtersData[]> = new EventEmitter();
    dynamicForm: FormGroup;
    submitButtonData: IButtonData = {
        buttonText: this.translate.instant('COMMON.APPLY'),
        buttonClass: 'custom',
        customClass: 'primary-small'
    };
    resetButtonData: IButtonData = {
        buttonText: this.translate.instant('FSQS.RESET_ALL'),
        buttonClass: 'custom',
        customClass: 'secondary-small'
    }
    checkBoxData: IDropdown;
    dividerDetails: DividerOption = {
        minLength: '100%',
        isHorizontal: true,
        color: '#D9EAF9'
    };
    formControlNames = [];
    initialValues = {};
    isFormValid = false;
    displayIndropDown = [];
    formDropDownStatus = { "classificationTypeId": false}
    
    showCustomDate:boolean;
    startDate:string;
    endDate:string;
    currentDate = new Date();
    isCustomDate:boolean;
    showCustomView:boolean;
    selectedEndDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate());
    constructor(private fb: FormBuilder, private sidePanelService: SidePanelService,
        private translate: TranslateService,
        private datepipe: DatePipe
    ) { }


    ngOnInit() {
        this.isFormValid = true;
        this.getFormStructure(this.filterData);
        this.getStartEndDate(this.filterData);
    }

    /**
    * @description This function converts the json filterdata to the dynamic form and creates form group
    * @param data 
    */

    getFormStructure(data: filtersData[]) : void{
        const formStructure = data;
        const formGroup = {};
        formStructure.forEach(control => {
            formGroup[control.name] = [control.options];
            this.initialValues[control.name] = control.options;
        });
        this.dynamicForm = this.fb.group(formGroup);
        this.formControlNames = Object.keys(this.dynamicForm.value);
        this.setDropDownComponent(this.filterData);
    }

    /**
     * @description This function sets the dropdown to select the default filter for each created dropdown component in the form
     * @param filters
     */

    setDropDownComponent(filters: filtersData[]) :void {
        this.displayIndropDown = [];
        filters.forEach(item => {
            if (item.type === 'dropdown') {
                item.options.forEach(value => {
                    if (value.checked) {
                        this.displayIndropDown.push(value);
                    }
                })
            }
        });
    }



    /**
    * @description This function submits the form by selected values and send the modified filterdata to the parent component
    */

    onSubmit() : void {
        this.filterData = this.mapFilterDataWithFormControl(this.dynamicForm.value);
        this.getFormErrors();
        if (this.isFormValid) {
            this.selectedFilterData.emit(this.filterData);
            this.sidePanelService.openSideWindow(false)
        }
    }

    /**
    * @description This function resets the complete form to the default state
    */

    resetAll(): void{
        this.displayIndropDown = [];
        this.filterData = this.mapFilterDataWithFormControl(this.initialValues);
        this.filterData.forEach(control => {
            if (control.type === 'dropdown') {
               control.options.forEach(value => {
                if (value.isDefault) {
                    value.checked = true
                    this.displayIndropDown.push(value)
                } else {
                    value.checked = false;
                }
               })
            }
            this.dynamicForm.controls[control.name].setValue(control.options);
        });
        this.getStartEndDate(this.filterData);
    }

    /**
    * @description This function resets each form control to the default state
    * @param $event
    */

    resetFormControl($event: string): void{
        this.dynamicForm.controls[$event].setValue(this.initialValues[$event]);
        this.filterData = this.mapFilterDataWithFormControl(this.dynamicForm.value);
        this.getStartEndDate(this.filterData);
    }

    /**
    * @description This function captures each detection in the form
    * @param $event
    */

    catchRecentChange($event,value): void {
        this.filterData = this.mapFilterDataWithFormControl(this.dynamicForm.value);
        if(value){
        this.showCustomDate = $event.find(x=>x.value === 'Custom').checked ? true : false;
        this.getStartEndDate(this.filterData);
        }

    }
        /**
    * @description get start date from today
    * @param days 
    */
    getStartDate(days) {
        const currentDate = new Date();
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - days);
    }
  /**
    * @description show Date Selector
    * @returns no value
    */
  valueCategories(): void {
    if(this.showCustomDate){
        this.showCustomView= !this.showCustomView;
        }
}
          /**
    * @description get start date from today
    * @param days 
    */
    getStartEndDate(data: filtersData[]): void {
        let value=data.filter(x => x.name === 'Time Range')[0].options.find(x => x.checked).value
       if(value === 'Custom'){
        this.startDate = null;
        this.endDate = null;
        this.showCustomView= !this.showCustomView;
       }
       else{
        this.showCustomView= false;
        this.startDate = this.getStartDate(value).toString();
        this.endDate = this.selectedEndDate.toString();
        this.setSelectedDates(this.startDate,this.endDate);
       }
    }

    /**
    * @description update date on selection change
    * @param days 
    */
    updateDate($event: IDropdownEventType) {
        this.startDate = $event.startDate;
        this.endDate = $event.endDate;
        if (this.startDate && this.endDate) {
            this.setSelectedDates(this.startDate,this.endDate);
        }
    }

    setSelectedDates(sDate:string,eDate:string){
        if (sDate && eDate) {
            this.filterData.forEach(item => {
                if (item.name === this.translate.instant('LIBRARY.DV.TIME_RANGE_TEXT')) {
                    item.options.forEach(value => {
                        value.secondText = ` (${this.datepipe.transform(sDate, "dd/MM/yyyy")} - ${this.datepipe.transform(eDate, "dd/MM/yyyy")})`;
                    })
                }
            })
        }
    }
    /**
    * @description This function changes the formcontrol values and original filterdata values based on the user selection , t
    * this is the common function for each and every action
    */

    mapFilterDataWithFormControl(initialFormValue: {}) : filtersData[] {
        this.filterData.forEach(filters => {
            for (let i = 0; i < this.formControlNames.length; i++) {
                if (filters.name === this.formControlNames[i]) {
                    return filters.options = initialFormValue[this.formControlNames[i]]
                }
            }
        });
        return this.filterData
    }

    /**
    * @description This function is used to detect the form errors based on the validation provided in the config file from the services
    */

    getFormErrors() : void {
        for (let index = 0; index < this.filterData.length; index++) {
            if (this.filterData[index].required) {
                this.filterData[index].valid = false;
                const isSelectedValues = this.filterData[index].options.findIndex(e => e.checked === true);
                if (isSelectedValues > -1) {
                    this.filterData[index].valid = true;
                } else {
                    this.isFormValid = false;
                    this.filterData[index].valid = false;
                }
            }
        }

    }

}
