<nalco-side-panel [headerDetails]="sidePanelOptions">
   <div side-panel-body>
      <div class="filter-template">
         <form [formGroup]="dynamicForm">
            <div class="filter-section" *ngFor="let filters of filterData">
               <h5 class="filter-type">{{filters.name}}</h5>
               <span class="reset-section float-right" (click)="resetFormControl(filters.name)"
                  *ngIf="!filters.isResetDisabled">{{'CONTROLLER_MANAGEMENT.RESET' | translate}}</span>
               <div class="row filter-type">
                  <span class="chip-box" [hidden]="filters.type === 'dropdown'"
                     [ngClass]="typeDetails.checked ? 'chip-box-bg' : 'chip-box-wt-bg'"
                     *ngFor="let typeDetails of filters.options">
                     <nalco-checkbox *ngIf="filters.type === 'checkbox'" [checkbox]="typeDetails"
                        formControlName="{{filters.name}}" (checkboxClick)='catchRecentChange($event)'></nalco-checkbox>
                     <nalco-radio-button *ngIf="filters.type === 'radio'" [radioButton]="typeDetails"
                        formControlName="{{filters.name}}"
                        (radioButtonClick)='catchRecentChange($event,typeDetails)'></nalco-radio-button>                  
                        <span class="date-chips" *ngIf="filters.type === 'radio' && typeDetails.checked && startDate && endDate">
                           {{typeDetails.secondText}}</span>
                           <i class="fa" *ngIf="filters.type === 'radio' && typeDetails.text === 'Custom'" (click)="valueCategories()"
                           [ngClass]="showCustomView? 'date-chips-custom-up':'date-chips-custom-down'" style="cursor: pointer;"></i>
                  </span>
                  <div class="col-lg-12">
                     <nalco-shared-dropdown *ngIf="filters.type === 'dropdown' && filters.options.length > 0"
                        [SearchType]="'list'" [list]="filters.options" [checkedItemsLimit]="filters.options.length"
                        [multiSelection]="false" [displayOnInit]="displayIndropDown"
                        [showFilterBycategory]="false" [showSearchBox]="true"
                        [dropDownSearchPlaceholder]="'ACTION_LOG.FILTERS.ENTER_ASSET_NAME_TO_SEARCH' | translate"
                        [placeholder]="'ACTION_LOG.FILTERS.SELECT_ASSET' | translate"
                        [noRecoredsLabel]="'COMMON.NO_RECORDS_FOUND' | translate" [showClearSelectionFlag]="false"
                        [isSelectAllAvailable]="false" [scrolltoTop]="true" 
                        [componentId]="'classificationTypeId'"
                        [dropdownStatus]="formDropDownStatus" (toggleEvent)="getValidationStatus($event)">
                     </nalco-shared-dropdown>
                  </div>
                  <!--  -->
                  <div class="no-site-selected" *ngIf="filters.type === 'dropdown' && filters.options.length === 0">
                     <span><img class="no-data-img" src="../../../../assets/images/filter-icons/actions-recommendations/no-data-warning.svg"></span>
                     <span class="no-site-text">{{'ACTION_LOG.FILTERS.NO_SITE_SELECTED' | translate}}
                       <div class="no-site-msg">{{'ACTION_LOG.FILTERS.SELECT_SITE_TO_ACCESS_SITE' | translate}}</div>
                     </span>
                  </div>
               </div>
               <span *ngIf="filters.required && !isFormValid && !filters.valid && filters.validationsMsg"
                  class="validation-error-msg">{{filters.validationsMsg}}</span>
                  <span *ngIf="filters.name === 'Time Range' && showCustomView">
                     <div *ngFor="let values of filters.options" class="custom-date-view" >
                        <custom-date-picker [showApplyButton]="false" class="custom-date" [sDate]="startDate" [edate]="endDate"
                        *ngIf="values.checked" [bottomView]="'month'"
                        (updateDate)="updateDate($event)">
                        </custom-date-picker>
                     </div>
                  </span>
               <nalco-divider [color]="dividerDetails.color" [length]="dividerDetails.minLength"
                  [isHorizontal]="dividerDetails.isHorizontal"></nalco-divider>

            </div>
         </form>
      </div>
   </div>
   <div side-panel-footer>
      <div class="footer-buttons float-right row">
         <nalco-button [buttonData]="resetButtonData" (click)="resetAll()"></nalco-button>
         <nalco-button [buttonData]="submitButtonData" (click)="onSubmit()"></nalco-button>
      </div>
   </div>
</nalco-side-panel>