import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidePanelComponent  } from './side-panel.component';
import { SidePanelService } from './services/side-panel.service';


@NgModule({
  declarations: [
    SidePanelComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SidePanelComponent
  ],
  providers:[SidePanelService]
})
export class SidePanelModule { }
