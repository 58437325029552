/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Injectable } from '@angular/core';
import { filtersData } from '../contracts/filter-container.model';
import { dynamicUuid } from '../enums/filter-container.constants'

@Injectable()
export class FilterContainerService {
    constructor() { }


    /**
    * @description This function creates the uuid dynamically for the config files and respective data where ever this function is used
    */

    createUUID() :string {
        const uuid = dynamicUuid.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
        return uuid;
    }

    /**
    * @description This function is used to set the dynamic id to each object in the array
    * @param obj    
    */

    setIdforDynamicComponents(obj : filtersData[]) : filtersData[] {
        for (let index = 0; index < obj.length; index++) {
            for (let j = 0; j < obj[index].options.length; j++) {
                obj[index].options[j].id = this.createUUID();
            }
        }
        return obj
    }
}
