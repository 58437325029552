/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Component ,OnInit ,Input} from '@angular/core';
import { SidePanelService } from './services/side-panel.service';
import { SidePanelOptions } from './constants/side-panel.model'

@Component({
  selector: 'nalco-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss']
})
export class SidePanelComponent implements OnInit {

  constructor(private sidePanelService : SidePanelService){

  }

  @Input() headerDetails :SidePanelOptions; 

  sidePanelValue : boolean;
  closeSidePanelValue:boolean;


  ngOnInit(): void {
    this.sidePanelService.triggerSidePanel.subscribe(value => this.sidePanelValue = value);
  }

    /**
    * @description This function is used to close the side opened side panel
    */

  closeSidePanel() : void{
    this.sidePanelService.openSideWindow(this.closeSidePanelValue);
  }
  

}
