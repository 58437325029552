import { Component, Input, Output, EventEmitter, forwardRef, OnInit, OnChanges, ChangeDetectorRef, ViewEncapsulation, HostListener, SimpleChanges, NgZone, ViewChild } from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule, FormControl } from '@angular/forms';
import { IDropdown, IDropdownEventType } from '../../contracts/dropdown-model';
import { DividerOption } from '../../../../shared-ui-refresh/divider/divider.model';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'nalco-button-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownComponent),
            multi: true
        }
    ],
    encapsulation: ViewEncapsulation.None
})

export class DropdownComponent implements ControlValueAccessor, OnChanges {

    @Input() ddData: IDropdown; // To get the data for the dropdown
    @Input() isDisabled = false; // Defines the enable/disable value of the dropdown
    @Input() iconClass: string = ''; // Defines the icon requirement on the dropdown
    @Input() showSelect: boolean; // Defines the select requirement on the dropdown
    @Input() name: string;  // Defines the sub-name of the dropdown
    @Input() options; //  Defines the options of the dropdown
    @Input() value: string = '';  // Binds the selected option
    @Input() showsecondtextLabel: boolean; // Defines the required validation of the dropdown
    @Input() isCustomDate: boolean;
    @ViewChild('myDropdown',{static: true}) myDropdown:NgbDropdown;
    
    @Output() ddChange = new EventEmitter<IDropdownEventType>(); // Emits the events when the dropdown options are selected
    selectedOptionTextVal: string;
    tooltipText = [];
    tooltipSecondText = [];
    dividerCss : DividerOption = {
        color: "#bac7d8",
        maxLength: "88%",
        isHorizontal: true
      }
    isCustomeOption :boolean;
    activeIds = ['customDate'];
    constructor(private cdRef: ChangeDetectorRef) { }

    changeInDropdown(name, value, text, secondText?) {
        this.isCustomeOption = text === 'Custom' && this.isCustomDate ? true : false;
        if (!this.isCustomeOption) {
            this.myDropdown.close();
        }
        let label = this.ddData.label;
        this.ddData.options.forEach(option => {
            if (option.value === value) {
                option.isSelected = true;
                option.checked = true;
            } else {
                option.isSelected = false;
                option.checked = false;
            }
        });
        if(!this.isCustomeOption){
        this.ddChange.emit({
            name: name,
            value: value,
            label: label,
            text: text,
            secondText: secondText
        });
        this._onChange(value);
        this._onTouched();
    }
    else{
        this.activeIds = ['customDate'];
    }
    }

    selectedOptionText() {
        let text = '';
        this.ddData.options.forEach(option => {
            if (option.isSelected) {
                if (this.showsecondtextLabel) {
                    text = option.secondText ? option.secondText : option.text;
                } else {
                    text = option.text ? option.text : option.secondText;
                }
            }
        });
        return text;
    }

    ngOnInit() {
        this.selectedOptionTextVal = '';
        this.tooltipText = [];
        this.tooltipSecondText = [];
        if (!this.value) { return; }
        this.ddData.options.forEach(option => {
            if (option.value === this.value) {
                option.isSelected = true;
            } else {
                option.isSelected = false;
            }
        });
    }
    ngAfterContentChecked(): void {
        this.cdRef.detectChanges();
    }

    ngOnChanges(changes) {
        if (changes.ddData !== undefined && !changes.ddData.firstChange && changes.ddData.currentValue === undefined) {
            this.ddData = changes.ddData.previousValue;
        }
    }
    getselectedOptionText(element: HTMLElement): void {
        this.selectedOptionTextVal = element.offsetWidth + 1 < element.scrollWidth ? this.selectedOptionText() : '';
    }
    getTooltip(element: HTMLElement, i: number, type: string): void {
        this.tooltipText = [];
        this.tooltipSecondText = [];
        if (element.offsetWidth + 1 < element.scrollWidth) {
            if (type == 'text1') {
                this.tooltipText[i] = this.ddData.options[i].text;
            } else if (type == 'text2') {
                this.tooltipSecondText[i] = this.ddData.options[i].secondText;
            }
        }
    }

    updateDate($event:IDropdownEventType)
    {
       this.activeIds = [''];
        this.ddChange.emit({
            name: $event.name,
            value: $event.value,
            label: $event.label,
            text: $event.value,
            secondText: $event.secondText,
            startDate: $event.startDate,
            endDate: $event.endDate,
        });  
        this.myDropdown.close();
    }


    // ControlValueAccessor implementation
    // ====================================

    // call if value was changed inside our component
    private _onChange = (_: any) => { };
    // call if input was "touched" .. !
    private _onTouched = () => { };

    // incoming change..
    public writeValue(val: any) {
        this.value = val;
    }

    public registerOnChange(fn: (_: any) => void): void { this._onChange = fn; }
    public registerOnTouched(fn: () => void): void { this._onTouched = fn; }
    @HostListener('document:click', ['$event']) clickedOutside(event) {
        if (event.target.className.indexOf !== undefined && this.isCustomDate &&
            !(event.target.className.includes('button-label') ||
                event.target.className.includes('dropdown-toggle') ||
                this.isCustomeOption )) {
                this.myDropdown.close();
        }
       }
}