import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { PopupSettings } from "@progress/kendo-angular-dateinputs";
import * as moment from 'moment';
import { ContainerRef } from 'ngx-infinite-scroll';
import { Subscription } from 'rxjs';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';
import { ErrorHandling } from '../../../shared/components/error-handling/error-handling';
import { XdComponentState } from '../../../shared/components/xd-container/xd-component';
import { ComponentState } from '../../../shared/components/xd-container/xd-component-state';
import { NavigateDV } from '../../../shared/models/NavigateDV';
import { DATE_FORMAT, LIMIT_STATUS, MODALS, PAGE_SIZE, RAPID_BIO, USER_STATUS} from '../../../e-data-entry/_enum/constant';
import { ContextPointConfigurations, Mapping, MeasurementInstanceConfigurations } from '../../../e-data-entry/_models/Config';
import { HistoricalData, MeasurementInstanceAnnotation, MeasurementInstanceLite, MeasurementValue, User } from '../../../e-data-entry/_models/HistoricalData';
import { SaveReading } from '../../../e-data-entry/_models/SaveReading';
import { EDataOverviewService } from '../../../e-data-entry/_services/e-data-overview.service';
import { EDataEntryModalsComponent } from '../../../e-data-entry/components/e-data-entry-modals/e-data-entry-modals.component';
import { ActivatedRoute } from '@angular/router';
import { SetupConfigResponse } from '../../../e-data-entry/_models/SetupConfig';

@Component({
  selector: 'nalco-custom-e-data-overview',
  templateUrl: './custom-e-data-overview.component.html',
  styleUrls: ['./custom-e-data-overview.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class CustomEDataOverviewComponent extends XdComponentState implements OnInit, AfterViewChecked, OnDestroy {

  @Input() siteCpID: any = '';
  @Input() subConfigId: any = '';
  @Input() plotChartIcon: boolean = true;
  @Input() isRow: boolean = true;
  @Input() showExpandBtn: boolean = true;
  @Input() showCollapsebtn: boolean = true;
  @Input() showCommaSeparator: boolean = true;
  @Input() showCompleteEntryBtn: boolean = true;
  @Input() showFilterData: boolean = true;
  @Input() DVAccess: boolean;
  @Input() historicalDataHeader: any;
  @Input() loadMoreHistoryDataBtn: boolean = true;
  @Input() historicalDataPageSize: any = PAGE_SIZE.DEFAULT_PAGE_SIZE;


  @Output() rowsLengthChange = new EventEmitter<boolean>();
  @Output() configRes = new EventEmitter<any>();
  @Output() subConfigNavigation = new EventEmitter<any>();
  @Output() Headers = new EventEmitter<any>();
  @Output() failedReadingsData = new EventEmitter<any>();
  @Output() savedReadingsCopyData = new EventEmitter<any>();

  public popupSettings: PopupSettings = {
    popupClass: "add-entry-datepicker",
  };

  rows: Array<ContextPointConfigurations> = [];
  subscriptions: Array<Subscription> = [];
  calulatedVariableList: any = [];
  calculatedMeasuremtmnts: any = [];
  historicalData = [];
  mapping: any = [];
  tempArray: any = [];
  selectedArray: any = [];
  headers = [];
  globalArr: any = [];
  selectedOptionsOnInit: any = [];
  Searcharray: any = [];
  savedReadingsCopy: any = [];
  savedReadings: any = [];
  failedReadings = [];
  DataSourceDetails: { [siteId: string]: [] } = {};
  dataSetAnnotations: any = [];

  onEditMode: boolean = false;
  expandbtn: boolean = false;
  collapsebtn: boolean = false;
  disableBtn: boolean = false;
  showDatepickerValue: boolean = false;
  isButtonDisabled: boolean = true;
  commentLoader: boolean = false;
  confirmModal: boolean = false;
  measurementCommentUpdate: boolean = false;
  measurementCommentAdded: boolean = false;
  storePreviousSite: boolean = false;
  measurementCommentFailed: boolean = false;
  deletedDataset: boolean = false;
  setEditComment: Boolean = false;
  dataSubmitWithoutReadings: boolean = false;
  dateLoader: boolean = false;
  disableEditBtn: boolean = false;
  successCommentModalOpened: boolean = false;
  dataEntrySavedModalOpened: boolean = false;
  disableCompleteEntryBtn = true;
  showEditSubConfigurationButton: boolean = true;
  showExportDataButton: boolean = true;
  isData: boolean = true;

  isDVAccess: boolean;
  loading: boolean = false;
  cultureCheck:boolean = false;

  subConfigurationCount: number;
  dagId: number;

  selectedDateAddNewEntry = "";

  maxDate: Date = new Date();
  value: Date = new Date();

  form: FormGroup;
  getLatestDatefromAPI;

  errorHandling = new ErrorHandling(this.modalService, this.translate);

  defaultPageSize = PAGE_SIZE.DEFAULT_PAGE_SIZE;
  limitsStatus = LIMIT_STATUS;

  popUpWindow: NgbModalRef;
  commentPopupWindow: NgbModalRef;
  commentSuccessWindow: NgbModalRef;
  confirmModalWindow: NgbModalRef;
  edataSubConfigurationModalWindow: NgbModalRef;
  savedDataEntryWindow: NgbModalRef;

  configResponse: SetupConfigResponse;
  saveConfigSetupRes: any;
  SamplePointComment: any;
  headerMeasurementDate: any;
  errorInfo: any;
  modalReference: any;
  measurementResultId: any;
  measurementOptionName: any;
  contextPointIdVal: any;
  samplepointIdVal: any;
  measurementIndexVal: any;
  objectLength: any;
  userCulture: any;
  private visibilityChangeListener: () => void;

  @ViewChild('datepicker1', { static: false }) customDateTimepicker: ContainerRef;
  @ViewChild('content', { static: false }) contentValue: ElementRef;
  @ViewChild('eDataTable', { static: false }) eDataTable: ElementRef;
  @ViewChild('commentPopup', { static: false }) commentPopupValue: ElementRef;
  @ViewChild('successCommentPopup', { static: false }) commentSuccessModalValue: ElementRef;
  @ViewChild('confirmPopup', { static: false }) confirmModalValue: ElementRef;
  @ViewChild('editSubConfigurationPopup', { static: false }) editSubConfigurationModalValue: ElementRef;
  @ViewChild('successNewEntryPopup', { static: false }) savedEntryModalValue: ElementRef;

  constructor(
    private edataService: EDataOverviewService,
    private localCacheService: LocalCacheService,
    private router: Router,
    private modalService: NgbModal,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.visibilityChangeListener = this.handleVisibilityChange.bind(this);
    document.addEventListener('visibilitychange', this.visibilityChangeListener);
    this.userCulture = JSON.parse(this.localCacheService.fetchData('UserSettings')).Culture;
    if(this.userCulture == "ja-JP"){
      this.cultureCheck = true;
    }
    // if(this.subConfigId != '')
    //   this.getConfigurationSetup(this.subConfigId, this.defaultPageSize, '', '');
    this.checkNull();
    this.isDVAccess = this.DVAccess;
    this.defaultPageSize = this.historicalDataPageSize >= 0 ? this.historicalDataPageSize : PAGE_SIZE.DEFAULT_PAGE_SIZE;

  }

  ngOnChanges(changes: SimpleChanges) {
    // if(changes.siteCpID)
    //   this.siteCpID = changes.siteCpID.currentValue != undefined ? changes.siteCpID.currentValue : '';
    // if(changes.subConfigId)
    //   this.subConfigId = changes.subConfigId.currentValue != undefined ? changes.subConfigId.currentValue : '';
    this.siteCpID = changes.siteCpID?.currentValue != undefined ? changes.siteCpID.currentValue : '';
    this.isData = changes.isRow?.currentValue != undefined ? changes.isRow.currentValue : true;
    this.historicalData = changes.historicalDataHeader?.currentValue != undefined ? changes.historicalDataHeader.currentValue : [];
    // this.historicalDataPageSize = changes.historicalDataPageSize?.currentValue != undefined ? changes.historicalDataPageSize?.currentValue : PAGE_SIZE.DEFAULT_PAGE_SIZE;
    // this.defaultPageSize = this.historicalDataPageSize;
    this.showFilterData = changes.showFilterData?.currentValue != undefined ? changes.showFilterData.currentValue : true;
    const queryparams = this.route.snapshot.queryParams;
    this.objectLength = Object.keys(queryparams).length;
    const querySubconfigurationid = queryparams['subconfigurationid'];
    // this.subConfigId = changes.subConfigId?.currentValue != undefined ? changes.subConfigId.currentValue : '';
    this.subConfigId =querySubconfigurationid != undefined ? querySubconfigurationid : '';
    if(this.objectLength <= 1) {
      this.rowsLengthChange.emit(true)
    }
    this.setState(ComponentState.ready);
    if(this.subConfigId != '')
      this.getConfigurationSetup(this.subConfigId, this.defaultPageSize, '', '');

  }
  // ngAfterViewInit() {
  //   console.log('this', this.IsEmpty)
  // }

   myParentInstance(): CustomEDataOverviewComponent{
    return this;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  getConfigurationSetup(configID, pageSize, scrollStartDate, scrollEndDate, loading?) {
    this.loading = loading != undefined ? loading : true;
    this.subConfigurationCount = 0;
    this.subConfigNavigation.emit(this.subConfigurationCount);
    this.onEditMode = false;
    this.disableEditBtn = false;
    let startDate = '';
    let endDate = '';
    if (this.dataEntrySavedModalOpened || this.dataSubmitWithoutReadings) {
      this.historicalData = [];
      this.mapping = [];
      this.setState(ComponentState.loading);
    }
    this.disableCompleteEntryBtn = true;
    this.disableBtn = false;
    if (scrollStartDate == '' && scrollEndDate == '') { // Default Dates
      const dateData = new Date();
      let startOn = new Date(dateData.getFullYear() - 1, dateData.getMonth(),
        dateData.getDate(), dateData.getHours(), dateData.getMinutes(), dateData.getSeconds()).toString();
      let endOn = new Date(dateData.getFullYear(), dateData.getMonth(),
        dateData.getDate(), dateData.getHours(), dateData.getMinutes(), dateData.getSeconds()).toString();
      startDate = moment(startOn).format(DATE_FORMAT.DATETIMEFORMAT);
      endDate = moment(endOn).format(DATE_FORMAT.DATETIMEFORMAT);
    }
    else {  // Scrolling Dates
      startDate = scrollStartDate;
      endDate = scrollEndDate;
    }
    this.subscriptions.push(this.edataService.getConfigSetup(configID, startDate, endDate, pageSize).subscribe(res => {
      this.rows = [];
      let sortDates = [];
      this.configResponse = res[0];
      this.configRes.emit(this.configResponse)
      // checking if measurements are more than partition size then multiple response of histoy api wil be there in response.
      if (res.length > 2) {
        let configArr = res[0];
        res.splice(0, 1);
        let newHistoryResponse = res;
        let appendedHistoryData: any = [];
        // appending all history response.
        newHistoryResponse.forEach(ar => {
          appendedHistoryData = [...appendedHistoryData, ...ar]
        })
        // removing duplicates.
        let newHistoryData = appendedHistoryData.filter((obj, pos, arr) => {
          return arr.map(mapObj => mapObj["MeasurementDate"]).indexOf(obj["MeasurementDate"]) === pos;
        });
        //sorting based on dates.
        newHistoryData.sort(function (a, b) {
          const x = a.MeasurementDate;
          const y = b.MeasurementDate;
          return ((x > y) ? -1 : ((x < y) ? 1 : 0));
        });
        // only 15 records need to show in UI.
        newHistoryData = newHistoryData.splice(0, 15);
        let newResponse = [];
        newResponse[0] = configArr;
        newResponse[1] = newHistoryData;
        res = newResponse;
      }
      if (res !== null && res.length !== 0) {
        // requires to create new user for add new entry.
        if (res[0].Mapping.length > 0) {
          res[0].Mapping.forEach(data => {
            this.mapping.push(new Mapping(data));
          });
        }
        if (res[0].ContextPointConfigurations !== null && res[0].ContextPointConfigurations.length > 0) {
          res[0].ContextPointConfigurations.forEach(element => {
            this.getCalculatedMeasurements(element);
            this.rows.push(new ContextPointConfigurations(element));
          });

        }
        if (res[1] !== null && res[1].length > 0) {
          this.saveConfigSetupRes = res[1];
          // this.historicalData = [];
          res[1].forEach(data => {
            this.historicalData.push(new HistoricalData(data));
            sortDates.push(data.MeasurementDate);
          });
        }
        if (sortDates.length > 0) {
          sortDates = sortDates.sort();
          this.getLatestDatefromAPI = sortDates[0]// Latest Date from api response-> EndDate for next Api call
        }
        else{
          this.getLatestDatefromAPI = startDate;
        }
        this.disableCompleteEntryBtn = true;
        this.getHeaders();
        this.dateLoader = false;
      }
      if (this.rows.length === 0) {
        this.showEditSubConfigurationButton = true;
        this.showExportDataButton = true;

      }
      if (this.rows.length !== 0) {
        this.showEditSubConfigurationButton = false;
        this.showExportDataButton = false;
      }
      (this.successCommentModalOpened || this.dataEntrySavedModalOpened) ? (this.dateLoader = false, this.successCommentModalOpened = false, this.dataEntrySavedModalOpened = false) : this.setState(ComponentState.ready);
      this.checkNull();
      this.loading = false;
      this.getSampleAndMeasurement(this.rows);
      this.rows.map((val, i) => val.order = val.order + i);
      this.tempArray = [...this.rows];
      if (this.selectedArray.length > 0) {
        this.onSourcesSelection(this.selectedArray);
      }
      this.setState(ComponentState.ready);
    },
      error => {
        this.setState(ComponentState.ready);
        this.errorInfo = error;
        this.errorInfo['_body'] = JSON.stringify(error.message);
        this.setState(ComponentState.error);
      }));
  }

  checkNull() {
    this.isData = false;
    if (this.rows.length !== 0)
      this.rowsLengthChange.emit(false);
    if (this.localCacheService.fetchData('EdataSelectedSite')) {
      const selectedSiteDetails = JSON.parse(this.localCacheService.fetchData('EdataSelectedSite'));
      this.siteCpID = selectedSiteDetails.ContextPointId ? selectedSiteDetails.ContextPointId : "";
    }
    if (this.siteCpID == "" || this.subConfigId == '' || this.rows.length == 0) {
      this.isData = true;
    }
    let expandcount = 0;
    let collapsecount = 0;
    let len = this.rows.length;
    this.rows.forEach(inner => {
      if (inner.ContextPointLite.expand == false) {
        collapsecount++;
      }
      if (inner.ContextPointLite.expand == true) {
        expandcount++;
      }
    })
    if (expandcount === len) {
      this.collapsebtn = false;
      this.expandbtn = true;
    }
    else if (collapsecount === len) {
      this.collapsebtn = true;
      this.expandbtn = false;
    }
    else if (expandcount !== len && expandcount >= 1) {
      this.collapsebtn = false;
      this.expandbtn = false;
    }
  }
  get rapidBio(): typeof RAPID_BIO {
    return RAPID_BIO;
  }

  getCalculatedMeasurements(measurementInstanceConfig) {
    measurementInstanceConfig.MeasurementInstanceConfigurations.forEach((variable) => {
      if (variable.MeasurementInstanceLite.OriginType == this.rapidBio.CAL_MEASUREMENT_ORIGIN_TYPE) {
        // (document.getElementById('input' + variable.MeasurementInstanceLite.MeasurementInstanceId) as HTMLInputElement).style.backgroundColor = "#28a745";
        let calculatedVariable = {
          measurementInstanceId: variable.MeasurementInstanceLite.MeasurementInstanceId,
          measurementInstanceCalculationMaps: variable.MeasurementInstanceCalculationMaps,
          measurementInstanceConfigurations: variable,
        };
        this.calculatedMeasuremtmnts.push(calculatedVariable);
      }
    });
  }

  loadMoreHistoryDataEntry() {
    this.dataSubmitWithoutReadings = false;
    if (!this.disableBtn) {
      if(this.getLatestDatefromAPI !== null && this.getLatestDatefromAPI !== undefined){
        // this.dateLoader = true;
        let currentDate = new Date();
        let currentthresholdDateTemp = new Date(currentDate.getFullYear() - DATE_FORMAT.MAX_THRESHOLD_DATE, currentDate.getMonth(),
        currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds()).toString();
        let currentthresholdDate = new Date(currentthresholdDateTemp);

        const dateData = new Date(this.getLatestDatefromAPI);
        // Threshold date allowed max 2 years and DATE_FORMAT.THRESHOLD_DATE is configurable.
        let thresholdDatetemp = new Date(dateData.getFullYear() - DATE_FORMAT.MAX_THRESHOLD_DATE, dateData.getMonth(),
          dateData.getDate(), dateData.getHours(), dateData.getMinutes(), dateData.getSeconds()).toString();
        let thresholdDate = new Date(thresholdDatetemp);
        if (dateData >= currentthresholdDate) {
          let startOn = new Date(dateData.getFullYear() - 1, dateData.getMonth(),
            dateData.getDate(), dateData.getHours(), dateData.getMinutes(), dateData.getSeconds()).toString();
          let endOn = new Date(dateData.getFullYear(), dateData.getMonth(),
            dateData.getDate(), dateData.getHours(), dateData.getMinutes() - 1, dateData.getSeconds()).toString();
          let scrollStartDate = moment(startOn).format(DATE_FORMAT.DATETIMEFORMAT);
          let scrollEndDate = moment(endOn).format(DATE_FORMAT.DATETIMEFORMAT);
          this.getConfigurationSetup(this.subConfigId, this.defaultPageSize, scrollStartDate, scrollEndDate);
        }
        else{
          this.dateLoader = false;
        }
      }
    }
  }

  collapseAll() {
    this.collapsebtn = true;
    this.expandbtn = false;
    this.rows.forEach(inner => {
      inner.ContextPointLite.expand = false;

    })
  }

  expandAll() {
    this.expandbtn = true;
    this.collapsebtn = false;
    this.rows.forEach(inner1 => {
      inner1.ContextPointLite.expand = true;
    })
  }

  expand(collection, expandedItem) {
    collection.forEach((item) => {
      if (item.ContextPointLite.ContextPointId === expandedItem.ContextPointLite.ContextPointId) {
        item.ContextPointLite.expand = !item.ContextPointLite.expand;
      }
    });
    this.buttonCheck(collection);
  }

  buttonCheck(collection) {
    let expandcount = 0;
    let collapsecount = 0;
    let len = collection.length;
    collection.forEach((item) => {
      if (item.ContextPointLite.expand == false) {
        collapsecount++;
      }
      if (item.ContextPointLite.expand == true) {
        expandcount++;
      }
    })
    if (expandcount === len) {
      this.collapsebtn = false;
      this.expandbtn = true;
    }

    else if (collapsecount === len) {
      this.collapsebtn = true;
      this.expandbtn = false;
    }
    else if (expandcount !== len && expandcount >= 1) {
      this.collapsebtn = false;
      this.expandbtn = false;

    }
  }

  getHeaders(flag?) {
    this.headers = []
    let tempHeader = [];
    let firstCol =
    {
      field: "samplename",
      title: "Sample Point and Parameter",
      date: "",
      userStatus: ""
    }
    tempHeader.push(firstCol);
    if (this.historicalData.length > 0) {
      // hardcoaded the Username as it is not unique, will have to change later.
      this.historicalData.forEach(history => {
        let userName = "";
        if (history.CreatedBy !== null) {
          userName = (history.CreatedBy.FirstName ? history.CreatedBy.FirstName : "") + " " +
            (history.CreatedBy.LastName ? history.CreatedBy.LastName : "");
        }
        let newCol = {
          field: "historyData",
          title: userName ? userName : null,
          date: history.MeasurementDate,
          userStatus: history.userStatus,
          dataSetComments: history.Annotations && history.Annotations.length > 0 ? true : false,
          annotations: history.Annotations,
          headerCommentExist: history.Annotations && history.Annotations.length > 0 ? true : false
        }
        tempHeader.push(newCol);
      });
    }
    this.headers = tempHeader;
    this.Headers.emit(this.headers);
    if(flag == 'clearSelectionFlag'){
      let index = this.headers.findIndex(val=>val.userStatus == 'new');
      if(index > -1){
        let userTitle = this.localCacheService.fetchData('User_FirstName', 'local') + ' ' + this.localCacheService.fetchData('User_LastName', 'session');
        this.headers[1] = this.headers[index];
        this.headers[1].title = userTitle;
        this.headers = this.headers.slice(0,-1);
        this.Headers.emit(this.headers);
      }
    }

  }

  valueGeneration(row, column) {
    let valueType = row.MeasurementInstanceLite.Measurement.NalcoNumericsUnitOrSpecies;
    let attributes = row.Attributes;
    let index: any;
    let optionId: any;
    let optionIndex: any;
    if (attributes != null && attributes.length > 0) {
      index = attributes.findIndex(ele => (ele.AttributeType == this.rapidBio.SCIENTIFIC_NOTATION && ele.AttributeValue == 'true'))
    }
    if (column.name !== 'name') {
      let userData = this.historicalData.filter(data => (data.MeasurementDate == column.date));
      if (userData.length > 0) {
        let tempData = userData[0].MeasurementInstanceReadings.filter(x => x.MeasurementInstanceLite.MeasurementInstanceId == row.MeasurementInstanceLite.MeasurementInstanceId);
        if (tempData.length == 0) {
          return '';
        }
        else {
          if (row.MeasurementInstanceLite.Measurement.OptionGroupId != null &&
            row.MeasurementOptionDetails.length > 0) {
            optionId = tempData[0] && tempData[0].MeasurementValue ? tempData[0].MeasurementValue.v : '';
            optionIndex = row.MeasurementOptionDetails.filter(ele => {
              return ele.OptionId == optionId
            }
            )
            if (optionIndex.length > 0) {
              return optionIndex[0].OptionName;
            }
            else {
              return '';
            }
          }
          else {
            if (index == undefined && valueType.toLowerCase() != "string") {
              return tempData[0] && tempData[0].MeasurementValue ? tempData[0].MeasurementValue.v : '';
            } else if (index != undefined || valueType.toLowerCase() == "string") {
              return tempData[0] && tempData[0].MeasurementValue ? tempData[0].MeasurementValue.ValueText : '';
            }
          }
        }
      }
    }
  }

  patchValue(row, column) {
    if (column.name !== 'name') {
      let userData = this.historicalData.filter(data => (data.MeasurementDate == column.date));
      if (userData.length > 0) {
        let tempData = userData[0].MeasurementInstanceReadings.filter(x => x.MeasurementInstanceLite.MeasurementInstanceId == row.MeasurementInstanceLite.MeasurementInstanceId);
        if (tempData.length == 0) {
          return '';
        }
        else {
          if (row.MeasurementInstanceLite.Measurement.OptionGroupId != null &&
            row.MeasurementOptionDetails.length > 0) {
            if (tempData[0].MeasurementValue.v == '' || tempData[0].MeasurementValue.v == undefined) {
              return tempData[0].MeasurementValue.v = '';
            }
            else {
              return tempData[0].MeasurementValue.v;
            }
          }
        }
      }
    }
  }

  commentPresent(row, column) {
    if (column.name !== 'name') {
      let userData = this.historicalData.filter(data => (data.MeasurementDate == column.date));
      if (userData.length > 0) {
        let tempData = userData[0].MeasurementInstanceReadings.filter(x => x.MeasurementInstanceLite.MeasurementInstanceId == row.MeasurementInstanceLite.MeasurementInstanceId);
        if (tempData.length == 0) {
          return '';
        }
        else {
          if (tempData[0].MeasurementInstanceAnnotation != null) {
            return tempData[0].MeasurementInstanceAnnotation.IsDeleted == false ? true : false;
          } else {
            return false;
          }
        }
      }
    }

  }

  commentDataFetch(row, column, contextPoint, measurementDetails, measurementLimit, dataCaptureUnit) {
    if (column.name !== 'name') {
      let userData = this.historicalData.filter(data => (data.MeasurementDate == column.date));
      let tempData = userData[0].MeasurementInstanceReadings.filter(x => x.MeasurementInstanceLite.MeasurementInstanceId == row.MeasurementInstanceLite.MeasurementInstanceId);
      if (tempData.length == 0) {
        return '';
      }
      else {
        let dataVal: any;
        if (row.MeasurementInstanceLite.Measurement.OptionGroupId != null && row.MeasurementOptionDetails.length != 0) {
          let valueId = tempData[0] && tempData[0].MeasurementValue ? tempData[0].MeasurementValue.v : '';
          let optionIndex = row.MeasurementOptionDetails.filter(ele => {
            return ele.OptionId == valueId
          }
          )
          if (optionIndex.length > 0) {
            dataVal = optionIndex[0].OptionName;
          }
          else {
            dataVal = '';
          }
        }
        else {
          let valueType = row.MeasurementInstanceLite.Measurement.NalcoNumericsUnitOrSpecies;
          if (valueType.toLowerCase() != "string") {
            dataVal = tempData[0] && tempData[0].MeasurementValue ? tempData[0].MeasurementValue.v : '';
          } else {
            dataVal = tempData[0] && tempData[0].MeasurementValue ? tempData[0].MeasurementValue.ValueText : '';
          }
        }
        if (tempData[0].MeasurementInstanceAnnotation != null) {
          const modalRef = this.modalService.open(EDataEntryModalsComponent, {
            backdrop: 'static',
            keyboard: false,
            windowClass: 'measurementComment-Modal'
          });
          this.modalReference = modalRef;
          modalRef.componentInstance.editData = MODALS.EDIT_COMMENTS;
          modalRef.componentInstance.modalText = MODALS.MEASUREMENT_COMMENTS;
          modalRef.componentInstance.displayData = {
            'contextPoint': contextPoint, 'measurementDetails': measurementDetails,
            'measurementLimit': measurementLimit, 'date': column.date, commentData: true,
            'measurementCellValue': dataVal, 'dataCaptureUnit': dataCaptureUnit != null ? dataCaptureUnit : null
          }
          modalRef.componentInstance.emitResponse.subscribe((res) => {
            if (res && res.result == true) {
              if (res.data.Annotation == null) {
                tempData[0].MeasurementInstanceAnnotation.IsDeleted == true;
                tempData[0].MeasurementInstanceAnnotation = null;
                row.measurementComments = false;
                this.measurementCommentAdded = false;
                this.commentPresent(row, column);
              };
              this.commentSuccessModal();
            }
            if (res && res.result == 'commentUpdated') {
              this.measurementCommentUpdate = true;
              this.openConfirmModal();
            }
          });
        }
      }

    }
  }

  onSourcesSelection(event) {
    let filterMeasurements: any = [];
    if (event == undefined) {
      this.selectedOptionsOnInit = [];
      this.selectedArray = [];
      this.rows = [...this.tempArray]
      this.buttonCheck(this.rows);
    }
    if (event && event.length == 0) {
      this.selectedArray = [];
      this.rows = [...this.tempArray]
    }
    if (event !== undefined && event.length > 0 && event[event.length - 1].checked === true) {
      // push in checked array
      let indexFound = this.selectedArray.findIndex(x => x.name === event[event.length - 1].display)
      if (indexFound == -1) {
        let getSameAppliedFilterID = this.selectedArray.findIndex(x => x.id === event[event.length - 1].id && x.value === event[event.length - 1].value);
        if (getSameAppliedFilterID == -1) {
          this.selectedArray.push({ id: event[event.length - 1].id, value: event[event.length - 1].value, checked: event[event.length - 1].checked, type: event[event.length - 1].type, name: event[event.length - 1].display, display: event[event.length - 1].display });
        }
      }
      this.globalArr = [];
      this.rows = [];
      // for each element that is check
      this.selectedArray.forEach((val) => {
        if (val.type === 'SamplePoint') {
          let matchedElements: Array<{ matchedSamplePoint: any, matchedMeasurements: Array<any>, order: any }> = [];
          let matchedMeasurements: any = [];
          let index = this.selectedArray.findIndex(x => x.type === 'Measurement'); // check in array if measurement is present
          //if measurement present, then filter by that measurement
          if (this.selectedArray.length > 0 && index !== -1) {
            filterMeasurements = this.selectedArray.filter((x) => {
              return x.type === 'Measurement'
            })
            // find the measurements filtered in that sample point
            this.tempArray.filter((ele) => {
              matchedMeasurements = [];
              if (ele.ContextPointLite && ele.ContextPointLite.ContextPointName != null && ele.ContextPointLite.ContextPointName != undefined) {
                let createdName = ele.ContextPointLite.ParentName ? ele.ContextPointLite.ParentName + ' | ' + ele.ContextPointLite.ContextPointName : ele.ContextPointLite.ContextPointName;
                if (createdName == val.display) {
                  filterMeasurements.filter((item) => {
                    ele.MeasurementInstanceConfigurations.filter((data) => {
                      if (data.MeasurementInstanceLite && data.MeasurementInstanceLite.DisplayName != null && data.MeasurementInstanceLite.DisplayName != undefined) {
                        if (item.name === data.MeasurementInstanceLite.DisplayName.toUpperCase()) {
                          matchedMeasurements.push(new MeasurementInstanceConfigurations(data));
                          let eleIndex = matchedElements.findIndex(x => x.matchedSamplePoint.ContextPointId == ele.ContextPointLite.ContextPointId);
                          if (eleIndex == -1) {
                            matchedElements.push({ matchedSamplePoint: ele.ContextPointLite, matchedMeasurements: matchedMeasurements, order: ele.order })
                          }
                        }
                      }
                    })
                  })
                }
              }
            })
            // add matched elements into globalArr
            matchedElements.forEach(element => {
              //check if row has already created for that sample point
              let selectedIndex = this.globalArr.findIndex(gArr => gArr.ContextPointLite.ContextPointId == element.matchedSamplePoint.ContextPointId && gArr.ContextPointLite.ParentId == element.matchedSamplePoint.ParentId)
              if (selectedIndex != -1) {
                let selectedConfigs = this.globalArr[selectedIndex].MeasurementInstanceConfigurations;
                this.globalArr[selectedIndex].MeasurementInstanceConfigurations = [...selectedConfigs, ...element.matchedMeasurements];
              } else {
                this.globalArr.push({
                  ContextPointLite: element.matchedSamplePoint,
                  MeasurementInstanceConfigurations: element.matchedMeasurements,
                  order: element.order
                })
              }
            });

            this.rows = this.globalArr;
            matchedMeasurements = [];
            matchedElements = [];
          }
          else {
            // if no measurement present, then filter by sample point only
            this.tempArray.filter((data, i) => {
              if (data.ContextPointLite && data.ContextPointLite.ContextPointName != null && data.ContextPointLite.ContextPointName != undefined) {
                let createdName = data.ContextPointLite.ParentName ? data.ContextPointLite.ParentName + ' | ' + data.ContextPointLite.ContextPointName : data.ContextPointLite.ContextPointName;
                if (createdName == val.display) {
                  this.globalArr.push(data);
                  this.rows = this.globalArr
                  this.selectedOptionsOnInit = event;
                }
              }
            })
          }
          this.dataSequenceandSort();
          this.globalArr.sort((a, b) => +a.order - +b.order);
        }
        if (val.type === 'Measurement') {
          let matchedElements: Array<{ matchedSamplePoint: any, matchedMeasurements: Array<any>, order: any }> = [];
          let matchedMeasurements: any = [];
          let index = this.selectedArray.findIndex(x => x.type === 'SamplePoint'); // check in array if samplePoint is present
          if (this.selectedArray.length > 0 && index == -1) {
            this.tempArray.filter((ele) => {
              matchedMeasurements = [];
              ele.MeasurementInstanceConfigurations.filter((data) => {
                if (data.MeasurementInstanceLite && data.MeasurementInstanceLite.DisplayName != null && data.MeasurementInstanceLite.DisplayName != undefined) {
                  if (val.name == data.MeasurementInstanceLite.DisplayName.toUpperCase()) {
                    // then push in global array - no need to check since no sample point present
                    matchedMeasurements.push(new MeasurementInstanceConfigurations(data));
                    let eleIndex = matchedElements.findIndex(x => x.matchedSamplePoint.ContextPointId == ele.ContextPointLite.ContextPointId);
                    if (eleIndex == -1) {
                      matchedElements.push({ matchedSamplePoint: ele.ContextPointLite, matchedMeasurements: matchedMeasurements, order: ele.order })
                    }
                  }
                }
              })
            })
            // add matched elements into globalArr
            matchedElements.forEach(element => {
              //check if row has already created for that sample point
              let selectedIndex = this.globalArr.findIndex(gArr => gArr.ContextPointLite.ContextPointId == element.matchedSamplePoint.ContextPointId && gArr.ContextPointLite.ParentId == element.matchedSamplePoint.ParentId)
              if (selectedIndex != -1) {
                let selectedConfigs = this.globalArr[selectedIndex].MeasurementInstanceConfigurations;
                this.globalArr[selectedIndex].MeasurementInstanceConfigurations = [...selectedConfigs, ...element.matchedMeasurements];
              } else {
                this.globalArr.push({
                  ContextPointLite: element.matchedSamplePoint,
                  MeasurementInstanceConfigurations: element.matchedMeasurements,
                  order: element.order
                })
              }
            });

            this.dataSequenceandSort();
            this.globalArr.sort((a, b) => +a.order - +b.order);

            this.rows = this.globalArr;
            matchedMeasurements = [];
            matchedElements = [];
          }
        }
      })
    }
    if (this.rows.length == 0) {
      let tempObj = this.headers[0];
      this.headers = [];
      this.headers.push(tempObj);
      this.Headers.emit(this.headers);
      this.expandbtn = true;
      this.collapsebtn = true;
    }
    else {
      this.buttonCheck(this.rows);
      if (this.headers.length > 1) {
        return;
      }
      else {
        this.getHeaders('clearSelectionFlag');
      }
    }
  }

  OnDeselect(event) {
    this.selectedArray = this.selectedArray.filter((val) => {
      return (val.name != event.display)
    })
    this.selectedOptionsOnInit = JSON.parse(JSON.stringify(this.selectedArray));
  }

  getSampleAndMeasurement(data) {
    let dropdownArray: any = [];
    let samplePointArray: any = [];
    samplePointArray.unshift({ id: JSON.stringify(this.siteCpID), display: 'General', type: 'general' })
    data.filter((ele) => {
      if (ele.ContextPointLite.ContextPointName !== undefined) {
        samplePointArray.push({ id: JSON.stringify(ele.ContextPointLite.ContextPointId), display: ele.ContextPointLite.ParentName ? ele.ContextPointLite.ParentName + ' | ' + ele.ContextPointLite.ContextPointName : ele.ContextPointLite.ContextPointName, type: 'SamplePoint' });
        dropdownArray.push({
          id: ele.ContextPointLite.ContextPointId, value: ele.ContextPointLite.ParentId,
          display: ele.ContextPointLite.ParentName ? ele.ContextPointLite.ParentName + ' | ' + ele.ContextPointLite.ContextPointName : ele.ContextPointLite.ContextPointName, type: 'SamplePoint'
        });
        dropdownArray.push(...ele.MeasurementInstanceConfigurations.map(item => {
          if (item.MeasurementInstanceLite && item.MeasurementInstanceLite.DisplayName != null && item.MeasurementInstanceLite.DisplayName != undefined) {
            return ({ id: item.MeasurementInstanceLite.MeasurementInstanceId, display: item.MeasurementInstanceLite.DisplayName.toUpperCase(), type: 'Measurement' })
          } else {
            return;
          }
        }));
      }
    })
    const unique = this.uniqueArray(dropdownArray, 'display');

    let getoldSelectedAppliedFilterList = this.Searcharray.filter(element => element.checked === true)
    if (getoldSelectedAppliedFilterList.length === 0) { // checking any old measurement or sample point filter applied
      this.Searcharray = this.sortByKey(unique, 'display');
    }
    else {
      this.selectedOptionsOnInit = getoldSelectedAppliedFilterList;
    }
    this.form = this.createFormGroup(samplePointArray)
  }

  uniqueArray(array, key) {
    const result = [];
    const map = new Map();
    for (const item of array) {
      if (item != undefined && item != null) {
        if (!map.has(item[key])) {
          map.set(item[key], true);
          result.push(item);
        }
      }
    }
    return result;
  }

  sortByKey(array, key) {
    return array.sort(function (a, b) {
      const x = a[key].toUpperCase();
      const y = b[key].toUpperCase();
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  showDatepicker() {
    this.value = new Date();
    this.maxDate = new Date();
    this.showDatepickerValue = !this.showDatepickerValue;
    this.customDateTimepicker.toggle(this.showDatepickerValue);

    // triggers calender picker SET button click action
    if (document.querySelector('.k-time-accept.k-button.k-primary') !== null) {
      const setButton = document.querySelector('.k-time-accept.k-button.k-primary');
      setButton.addEventListener('click', (e) => {
        this.selectedDateTime(this.value);
      });
    }
    if(document.querySelector('.k-button.k-time-cancel') != null) {
      const cancelButton = document.querySelector('.k-button.k-time-cancel');
      cancelButton.addEventListener('click', (e) => {
        this.showDatepickerValue = !this.showDatepickerValue;
        this.customDateTimepicker.toggle(this.showDatepickerValue);
      })
    }
  }
  
  private handleVisibilityChange(): void {
    if (document.hidden && this.showDatepickerValue) {
      this.showDatepickerValue = !this.showDatepickerValue;
      this.customDateTimepicker.toggle(this.showDatepickerValue);
    }
  }

  openModal() {
    this.popUpWindow = this.modalService.open(this.contentValue, { backdrop: 'static', keyboard: false });
  }

  closeModal() {
    this.popUpWindow.close();
  }

  addNewEntry() {
    this.calulatedVariableList = [];
    this.deletedDataset = false;
    this.failedReadings = [];
    this.failedReadingsData.emit(this.failedReadings);
    this.disableBtn = true;
    this.disableEditBtn = true;
    let newCol = {
      field: "historyData",
      title: this.localCacheService.fetchData('User_FirstName', 'local') + ' ' + this.localCacheService.fetchData('User_LastName', 'session'),
      date: this.selectedDateAddNewEntry,
      userStatus: USER_STATUS.NEW,
    }
    this.headers.splice(1, 0, newCol);
    this.Headers.emit(this.headers);
    // to add new user data in history data array. Require to preserve value when user expand and collapse.
    if (this.mapping.length > 0) {
      let user = new HistoricalData();
      user.MeasurementDate = this.selectedDateAddNewEntry;
      user.userStatus = USER_STATUS.NEW;
      this.mapping.forEach(el => {
        let userData = {
          MeasurementInstanceAnnotation: new MeasurementInstanceAnnotation(),
          MeasurementValue: new MeasurementValue(),
          MeasurementInstanceLite: new MeasurementInstanceLite(),
          User: new User()
        };
        userData.MeasurementInstanceLite.MeasurementInstanceId = el.MeasurementInstanceId;
        userData.User.FirstName = this.localCacheService.fetchData('User_FirstName', 'local');
        userData.User.LastName = this.localCacheService.fetchData('User_LastName', 'session');
        user.MeasurementInstanceReadings.push(
          userData
        )
      });
      this.historicalData.push(user);
      const element = document.getElementById("e-data-table");
      if (element !== null) {
        element.scrollLeft = 0; // Add new Entry at first position , Scroll Left to position 0.
      }
    }
  }

  valueChange($event, inputValue, column, row, cpid, optionName?, retry: boolean = false) {
    row.MeasurementInstanceLite.state = 1;
    // if value is made empty then no need to show loading icon.
    if (inputValue !== "") {
      row.MeasurementInstanceLite.state = 2;
    }
    let savedData = new SaveReading();
    savedData.MeasurementValue.i = column.date;
    savedData.MeasurementInstanceLite.ContextPointId = cpid;
    let valueType = row.MeasurementInstanceLite.Measurement.NalcoNumericsUnitOrSpecies;
    savedData.MeasurementInstanceLite.Measurement.NalcoNumericsUnitOrSpecies = valueType;
    savedData.MeasurementInstanceLite.MeasurementInstanceId = row.MeasurementInstanceLite.MeasurementInstanceId;
    savedData.DataCaptureDimensionalSubUnit = row.DataCaptureDimensionalSubUnit == null ? null : row.DataCaptureDimensionalSubUnit;
    savedData.MeasurementInstanceLite.Measurement.OptionGroupId = row.MeasurementInstanceLite.Measurement.OptionGroupId;
    savedData.MeasurementInstanceLite.OriginType = row.MeasurementInstanceLite.OriginType;
    savedData.MeasurementInstanceLite.SourceApplication = row.MeasurementInstanceLite.SourceApplication;
    let tempData = this.historicalData.filter(element => (element.MeasurementDate == column.date))[0].MeasurementInstanceReadings.
      filter(x => x.MeasurementInstanceLite.MeasurementInstanceId == row.MeasurementInstanceLite.MeasurementInstanceId);
    if (inputValue == "." || inputValue == "-") {
      inputValue = inputValue + "0";
    }
    // to save the input box value once user enter the same.
    if (row.MeasurementInstanceLite.Measurement.OptionGroupId != null) {
      tempData[0].MeasurementValue.v = inputValue !== "" ? parseFloat(inputValue) : null;
      savedData.MeasurementValue.v = inputValue !== "" ? parseFloat(inputValue) : null;
      if(optionName != 'Select'){
      tempData[0].MeasurementValue.ValueText = optionName;
      savedData.MeasurementValue.ValueText = optionName;
      }
    }
    else {
      if (valueType.toLowerCase() != "string") {
        tempData[0].MeasurementValue.v = inputValue !== "" ? parseFloat(inputValue) : null;
        savedData.MeasurementValue.v = inputValue !== "" ? parseFloat(inputValue) : null;
      }
      else {
        tempData[0].MeasurementValue.ValueText = inputValue;
        savedData.MeasurementValue.ValueText = inputValue;

      }

    }
    // to update the value in Failed Readings if any object is already present for that value during value change.
    if (this.failedReadings.length > 0) {
      let failedValue = this.failedReadings.filter(x => x.row.MeasurementInstanceLite.MeasurementInstanceId == row.MeasurementInstanceLite.MeasurementInstanceId);
      if (failedValue.length > 0) {
        if (row.MeasurementInstanceLite.Measurement.OptionGroupId != null) {
          failedValue[0].inputValue = inputValue;
          failedValue[0].savedData.MeasurementValue.v = inputValue !== "" ? parseFloat(inputValue) : null;
          if(optionName != 'Select'){
          failedValue[0].ValueText = optionName;
          failedValue[0].savedData.MeasurementValue.ValueText = optionName;
          }
        }
        else {
          if (valueType.toLowerCase() != "string") {
            failedValue[0].inputValue = inputValue;
            failedValue[0].savedData.MeasurementValue.v = inputValue !== "" ? parseFloat(inputValue) : null;
          } else {
            failedValue[0].inputValue = inputValue;
            failedValue[0].savedData.MeasurementValue.v = inputValue
          }
        }
      }
    }
    /**
    * Check entered MeasurementInstanceId is a calculated measurement
    */
    this.checkMeasurementInstanceCalculationMapsVariables(inputValue, column, row, cpid, savedData);
    this.saveHistoryData(inputValue, row, savedData, valueType, tempData, false);
  }

  checkMeasurementInstanceCalculationMapsVariables(inputValue, column, row, cpid, savedData) {
    /**
    * Check Edata entry input is a calculated measurement
    * Code will check if all the measurementInstanceCalculationMaps variables is having value in input box.
    * Call the calculation API to backend and bind the calculated result to readonly caluclated measurement input box.
    */
    let allmeasurementInstanceCalcMaps = [];
    let checkAllVariablesValues = [];
    let toMeasurementInstanceId: any = [];
    // to get the parent of edited variable.
    this.calculatedMeasuremtmnts.filter(list => {
      list.measurementInstanceCalculationMaps.filter(id => {
        if (id.FromMeasurementInstanceId === row.MeasurementInstanceLite.MeasurementInstanceId) {
          toMeasurementInstanceId.push(id.ToMeasurementInstanceId);
          let oldToMeasurementInstanceId = this.calulatedVariableList.findIndex(element => element.measurementInstanceId == list.measurementInstanceId)
          if (oldToMeasurementInstanceId == -1) {
            this.calulatedVariableList.push(
              {
                'measurementInstanceId': list.measurementInstanceId,
                'savedData': []
              }
            );
          }
          // add new entry and differnetiate it with ToMeasurementInstanceId.
          let previousMeasurementInstanceId = checkAllVariablesValues.findIndex(element => element.measurementInstanceId == list.measurementInstanceId)
          if (previousMeasurementInstanceId == -1) {
            checkAllVariablesValues.push(
              {
                'measurementInstanceId': list.measurementInstanceId,
                'val': []
              }
            );
          }
        }
      })
    })
    this.calculatedMeasuremtmnts.forEach(item => {
      item.measurementInstanceCalculationMaps.forEach(variable => {
        if (variable.FromMeasurementInstanceId === row.MeasurementInstanceLite.MeasurementInstanceId) {
          allmeasurementInstanceCalcMaps.push(item);
          this.calulatedVariableList.forEach(val => {
            if (val.measurementInstanceId == variable.ToMeasurementInstanceId) {
              let oldSavedataFindIndex = val.savedData.findIndex(element => element.MeasurementInstanceLite.MeasurementInstanceId == savedData.MeasurementInstanceLite.MeasurementInstanceId)
              if (oldSavedataFindIndex > -1) {
                val.savedData.splice(oldSavedataFindIndex, 1); // Remove old entry
              }
              val.savedData.push(savedData);
            }
          })
        } else if (variable.FromMeasurementInstanceId !== row.MeasurementInstanceLite.MeasurementInstanceId &&
          this.onEditMode == true && toMeasurementInstanceId.length > 0) {
          if (toMeasurementInstanceId.includes(variable.ToMeasurementInstanceId)) {
            let count = 0;
            item.measurementInstanceCalculationMaps.forEach(data => {
              if (!toMeasurementInstanceId.includes(data.ToMeasurementInstanceId)) {
                count++;
              }
            })
            if (count == 0) {
              let existingVariable = new SaveReading();
              // in edit mode if one variable is edited then need to check value of other variables so that we can the value of Calc Param.
              let getIndex = this.historicalData.findIndex(value => value.MeasurementDate == savedData.MeasurementValue.i);
              if (getIndex != -1) {
                this.historicalData[getIndex].MeasurementInstanceReadings.filter(val => {
                  if (val.MeasurementInstanceLite.MeasurementInstanceId == variable.FromMeasurementInstanceId) {
                    existingVariable.MeasurementValue.v = val.MeasurementValue.v;
                  }
                });
              }
              this.tempArray.filter(el => {
                el.MeasurementInstanceConfigurations.filter(data => {
                  if (data.MeasurementInstanceLite.MeasurementInstanceId == variable.FromMeasurementInstanceId) {
                    existingVariable.DataCaptureDimensionalSubUnit = data.DataCaptureDimensionalSubUnit;
                    existingVariable.MeasurementInstanceLite = data.MeasurementInstanceLite;
                  }
                });
              });
              this.calulatedVariableList.forEach(val => {
                if (val.measurementInstanceId == variable.ToMeasurementInstanceId) {
                  let oldSavedataFindIndex = val.savedData.findIndex(element => element.MeasurementInstanceLite.MeasurementInstanceId == existingVariable.MeasurementInstanceLite.MeasurementInstanceId)
                  if (oldSavedataFindIndex > -1) {
                    val.savedData.splice(oldSavedataFindIndex, 1); // Remove old entry
                  }
                  // created the existing variable object and pushed it after splicing it to avoid duplication.
                  existingVariable.MeasurementValue.i = column.date;
                  if (existingVariable.MeasurementValue.v != null) {
                    val.savedData.push(existingVariable);
                  }
                }
              })
            }

          }
        }
      });
    });
    if (allmeasurementInstanceCalcMaps.length > 0) {
      allmeasurementInstanceCalcMaps.forEach((mapVariable) => {
        mapVariable.measurementInstanceCalculationMaps.forEach(mapValue => {
          checkAllVariablesValues.forEach(calVal => {
            if (calVal.measurementInstanceId == mapValue.ToMeasurementInstanceId) {
              // get all calculated variable value
              let value = this.getCalculatedMeasurementInput(column, mapValue.FromMeasurementInstanceId);
              value != null ? value = value.toString() : (value = value, this.markasInfinity(mapValue.ToMeasurementInstanceId, 'restoreCell'));
              calVal.val.push(value);
            }
          })
        });
      });
      if (checkAllVariablesValues.length > 0) {
        let filterNonEmptyVariables = checkAllVariablesValues.filter(item => {
          if (item.val.length > 0) {
            let count = 0;
            item.val.forEach(val => {
              if (val == null || val == '' || val == "" || val.trim() == '') {
                count++;
              }
            })
            if (count == 0) {
              return item;
            }
          }
        })


        // not empty and all variables have value, get savedData from Calculated Variable.
        if (filterNonEmptyVariables.length > 0) {
          let calcultedSaveLists = [];
          filterNonEmptyVariables.forEach(mapVariable => {
            let mapVariableMeasurementInstanceId = this.calulatedVariableList.filter(data => data.measurementInstanceId == mapVariable.measurementInstanceId);
            if (mapVariableMeasurementInstanceId.length > 0) {
              calcultedSaveLists.push(...mapVariableMeasurementInstanceId);
            }
          });
          /**
          * Below getCalculationMapsVariablesByFormula API called and passed the parameters this.calculatedMeasuremtmnts, calcultedSaveLists,
          * Parameter 1 -  this.calculatedMeasuremtmnts
          * Parameter 2 -  calcultedSaveLists
          * calling API
          */
          calcultedSaveLists.forEach(data => {
            this.getCalculationMapsVariablesByFormula(inputValue, column, row, cpid, this.calculatedMeasuremtmnts, data, allmeasurementInstanceCalcMaps);
          })
        }
        let filterEmptyId = [];
        allmeasurementInstanceCalcMaps.forEach(el => {
          if (filterNonEmptyVariables.length > 0) {
            filterNonEmptyVariables.forEach(item => {
              if (el.measurementInstanceId !== item.measurementInstanceId) {
                filterEmptyId.push(el.measurementInstanceId);
              }
            })
          } else {
            filterEmptyId.push(el.measurementInstanceId);
          }
        })
        if (filterEmptyId.length > 0) {
          filterEmptyId.forEach(id => {
            let calcValue = this.getCalculatedMeasurementInput(column, id);
            calcValue = calcValue != null ? calcValue.toString() : calcValue;
            if (calcValue == null || calcValue == '' || calcValue == "" || calcValue.trim() == '' || calcValue == undefined) {
            } else {
              let getToMeasurementInstanceId = this.calculatedMeasuremtmnts.find((matchId) => matchId.measurementInstanceId === id);
              this.saveClaculatedMeasurementValue('', column, getToMeasurementInstanceId.measurementInstanceConfigurations, cpid);
            }
          })

        }
      }
    }
  }

  getCalculationMapsVariablesByFormula(inputValue, column, row, cpid, calculatedMeasuremtmnts, calcultedSaveLists, allmeasurementInstanceCalcMaps) {
    let getToMeasurementInstanceId = calculatedMeasuremtmnts.find(matchId => matchId.measurementInstanceId === calcultedSaveLists.measurementInstanceId)
    this.measurementResultId = getToMeasurementInstanceId.measurementInstanceId
    let variableReadingPayload = {
      "variableReadings": calcultedSaveLists.savedData,
      "calculatedMeasurementInstanceConfiguration": getToMeasurementInstanceId.measurementInstanceConfigurations
    }
    this.subscriptions.push(this.edataService.getCalulatedMeasurementValueByFormula(variableReadingPayload).subscribe(response => {
      if (response || response == 0) {
        // response result should be the inputValue
        if (response !== "Infinity" && response !== "-Infinity" && response !== "NaN" && response !== undefined) {
          this.saveClaculatedMeasurementValue(response, column, getToMeasurementInstanceId.measurementInstanceConfigurations,
            getToMeasurementInstanceId.measurementInstanceConfigurations.MeasurementInstanceLite.ContextPointId);
          this.markasInfinity(getToMeasurementInstanceId.measurementInstanceId, false);
        }
        else {
          getToMeasurementInstanceId.measurementInstanceConfigurations.MeasurementInstanceLite.state = 1;
          this.markasInfinity(getToMeasurementInstanceId.measurementInstanceId, true);
        }
      }
    }, error => {
      // Save null
      this.errorHandling.showErrorPopup(error);
      this.saveClaculatedMeasurementValue('', column, getToMeasurementInstanceId.measurementInstanceConfigurations, cpid);
    }
    ));
  }

  saveClaculatedMeasurementValue(inputValue, column, row, cpid) {
    row.MeasurementInstanceLite.state = 1;
    // if value is made empty then no need to show loading icon.
    if (inputValue !== "") {
      row.MeasurementInstanceLite.state = 2;
    }
    let savedData = new SaveReading();
    savedData.MeasurementValue.i = column.date;
    savedData.MeasurementInstanceLite.ContextPointId = cpid;
    let valueType = row.MeasurementInstanceLite.Measurement.NalcoNumericsUnitOrSpecies;
    savedData.MeasurementInstanceLite.Measurement.NalcoNumericsUnitOrSpecies = valueType;
    savedData.MeasurementInstanceLite.MeasurementInstanceId = row.MeasurementInstanceLite.MeasurementInstanceId;
    savedData.DataCaptureDimensionalSubUnit = row.DataCaptureDimensionalSubUnit == null ? null : row.DataCaptureDimensionalSubUnit
    savedData.MeasurementInstanceLite.Measurement.OptionGroupId = row.MeasurementInstanceLite.Measurement.OptionGroupId;
    savedData.MeasurementInstanceLite.OriginType = row.MeasurementInstanceLite.OriginType;
    savedData.MeasurementInstanceLite.SourceApplication = row.MeasurementInstanceLite.SourceApplication;
    let tempData = this.historicalData.filter(element => (element.MeasurementDate == column.date))[0].MeasurementInstanceReadings.
      filter(x => x.MeasurementInstanceLite.MeasurementInstanceId == row.MeasurementInstanceLite.MeasurementInstanceId);
    if (inputValue == "." || inputValue == "-") {
      inputValue = inputValue + "0";
    }
    // to save the input box value once user enter the same.
    if (row.MeasurementInstanceLite.Measurement.OptionGroupId != null) {
      tempData[0].MeasurementValue.v = inputValue !== "" ? parseFloat(inputValue) : null;
      savedData.MeasurementValue.v = inputValue !== "" ? parseFloat(inputValue) : null;
      tempData[0].MeasurementValue.ValueText = this.measurementOptionName;
      savedData.MeasurementValue.ValueText = this.measurementOptionName;
    } else {
      if (valueType.toLowerCase() != "string") {
        tempData[0].MeasurementValue.v = inputValue !== "" ? parseFloat(inputValue) : null;
        savedData.MeasurementValue.v = inputValue !== "" ? parseFloat(inputValue) : null;
      } else {
        tempData[0].MeasurementValue.ValueText = inputValue;
        savedData.MeasurementValue.ValueText = inputValue;
      }
    }
    // (document.getElementById('input' + savedData.MeasurementInstanceLite.MeasurementInstanceId) as HTMLInputElement).style.backgroundColor = "#b6d7a8";
    this.saveHistoryData(inputValue, row, savedData, valueType, tempData, false);
  }

  saveHistoryData(inputValue, row, savedData, valueType, tempData, retry: boolean = false) {
    if (inputValue !== "") {
      row.MeasurementInstanceLite.state = 2;
    }
    this.subConfigurationCount = 1;
    this.subConfigNavigation.emit(this.subConfigurationCount);
    this.subscriptions.push(this.edataService.saveMeasurementInstanceReading(savedData).subscribe(res => {
      this.disableCompleteEntryBtn = false;

      if (res !== null && res.length !== 0) {
        if (res == true) {
          // if Measurement instance id is already present in failedReadings array then need to splice it as it is saved successfully.
          if (this.failedReadings.length > 0) {
            const objWithIdIndex = this.failedReadings.findIndex((obj) => obj.row.MeasurementInstanceLite.MeasurementInstanceId == row.MeasurementInstanceLite.MeasurementInstanceId);
            if (objWithIdIndex != -1) {
              this.failedReadings.splice(objWithIdIndex, 1);
              this.failedReadingsData.emit(this.failedReadings);
            }
          }
          // to save the value which are already saved so that if user edits the values again then failure can be handled.
          if (this.savedReadings.length > 0) {
            let existingReading = this.savedReadings.filter(value => (value.measurementInstanceId == row.MeasurementInstanceLite.MeasurementInstanceId));
            if (existingReading[0] != undefined) {
              this.savedReadings.forEach(data => {
                if (data.measurementInstanceId == existingReading[0].measurementInstanceId) {
                  data.savedVal = inputValue;
                }
              });
            } else {
              this.savedReadings.push(
                {
                  measurementInstanceId: row.MeasurementInstanceLite.MeasurementInstanceId,
                  savedVal: inputValue
                }
              );
            }
          } else {
            this.savedReadings.push(
              {
                measurementInstanceId: row.MeasurementInstanceLite.MeasurementInstanceId,
                savedVal: inputValue
              }
            );
          }
          // if value is made empty then no need to show saved icon.
          if (inputValue !== "") {
            row.MeasurementInstanceLite.state = 3;
          }
        } else if (res == false) {
          row.MeasurementInstanceLite.state = 4;
          // to fetch all the failed readings in array.
          this.createFailedReadingsArray(inputValue, row, savedData, valueType, tempData);
        }
        this.savedReadingsCopy = [...this.savedReadings];
        this.savedReadingsCopy = this.savedReadingsCopy.filter((ele) => { return ele.savedVal != "" });
        this.savedReadingsCopyData.emit(this.savedReadingsCopy);
      }
      this.setState(ComponentState.ready);
    },
      error => {
        this.setState(ComponentState.ready);
        // to fetch all the failed readings in array.
        this.createFailedReadingsArray(inputValue, row, savedData, valueType, tempData);
        row.MeasurementInstanceLite.state = 4;
        if (500 <= error.status && error.status < 600) {
          row.MeasurementInstanceLite.state = 4;
        } else if (error.status === 417) {
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.errorHandling.showErrorPopup(error);
        }
        // this.setState(ComponentState.error);
      }
    ));
  }

  // to fetch all the failed readings in array.
  createFailedReadingsArray(inputValue, row, savedData, valueType, tempData) {
    let failedReadingDetails = {
      "inputValue": inputValue,
      "savedData": savedData,
      "row": row,
      "valueType": valueType,
      "tempData": tempData
    }
    if (this.failedReadings.length > 0) {
      let existingFailedReading = this.failedReadings.filter(value =>
        (value.row.MeasurementInstanceLite.MeasurementInstanceId == row.MeasurementInstanceLite.MeasurementInstanceId));
      if (existingFailedReading[0] != undefined) {
        this.failedReadings.forEach(data => {
          if (data.row.MeasurementInstanceLite.MeasurementInstanceId == existingFailedReading[0].row.MeasurementInstanceLite.MeasurementInstanceId) {
            data.inputValue = inputValue;
          }
        });
        this.failedReadingsData.emit(this.failedReadings);
      } else {
        this.failedReadings.push(failedReadingDetails);
        this.failedReadingsData.emit(this.failedReadings);
      }
    } else {
      this.failedReadings.push(failedReadingDetails);
      this.failedReadingsData.emit(this.failedReadings);
    }
  }

  selectedDateTime(event) {
    this.subConfigurationCount = 1;
    this.subConfigNavigation.emit(this.subConfigurationCount);
    this.savedReadingsCopy = [];
    this.savedReadingsCopyData.emit(this.savedReadingsCopy);
    this.failedReadings = [];
    this.failedReadingsData.emit(this.failedReadings);
    this.measurementCommentAdded = false;
    this.measurementCommentFailed = false;
    this.dateLoader = true;
    if (event) {
      let dateValue = (event.getMonth() + 1) + '-' + event.getDate() + '-' + event.getFullYear()
        + ' ' + event.getHours() + ':' + event.getMinutes() + ':' + '00';
      this.headerMeasurementDate = dateValue;
      this.subscriptions.push(this.edataService.IsReadingCollectionExists(this.subConfigId, dateValue).subscribe(response => {
        if (response) {
          this.openModal();
        }
        else {
          this.selectedDateAddNewEntry = dateValue;
          this.addNewEntry();
        }
        this.dateLoader = false;
        this.showDatepickerValue = false;
      },
        error => {
          this.dateLoader = false;
          this.showDatepickerValue = false;
          this.setState(ComponentState.ready);
          this.errorInfo = error;
          this.errorInfo['_body'] = JSON.stringify(error.message);
          this.setState(ComponentState.error);
        }
      ));
    }
  }

  calenderPicker(ev) {
    if (ev.target.className === 'k-icon k-i-calendar') {
      this.value = new Date();
      this.maxDate = new Date();
      this.showDatepicker();
    }
  }

  sampleRowSelected(i, event) {
    this.contextPointIdVal = i;
    this.samplepointIdVal = '';
    this.measurementIndexVal = '';
    event.preventDefault();
    event.stopPropagation();
  }


  edataRowSelected(samplepointId, measurementIndex, event) {
    this.contextPointIdVal = '';
    this.samplepointIdVal = samplepointId;
    this.measurementIndexVal = measurementIndex;
    event.preventDefault();
    event.stopPropagation();
  }

  getDataSourceDetails(selectedSiteId) {
    return new Promise((res) => {
      this.edataService.getDataSourceDetails(selectedSiteId).subscribe((response) => {
        if (response) {
          this.DataSourceDetails[selectedSiteId] = response;
        }
        res(true);
      });
    })
  }

  updateDVUrlAndNavigate(measurementObject, measurementDataSource, measurementInstanceId: string): void {
    const stuiId = measurementObject && measurementObject.MeasurementInstanceLite && measurementObject.MeasurementInstanceLite.StuiId ? measurementObject.MeasurementInstanceLite.StuiId.toString() : '0';
    const stuiTypeId = measurementObject && measurementObject.MeasurementInstanceLite && measurementObject.MeasurementInstanceLite.StuiTypeId ? measurementObject.MeasurementInstanceLite.StuiTypeId.toString() : '0';
    const portSubTypeId = measurementObject && measurementObject.MeasurementLimit && measurementObject.MeasurementLimit.PortSubTypeId ? measurementObject.MeasurementLimit.PortSubTypeId.toString() : '0';
    const selectedSiteId = JSON.parse(this.localCacheService.fetchData('EdataSelectedSite')).ContextPointId;
    const dataDV = new NavigateDV();
    dataDV.NavigationSource = "e-data";
    dataDV.TimeRange = "6";
    dataDV.SiteId = selectedSiteId.toString();
    dataDV.StuiId = stuiId;
    dataDV.StuiTypeId = stuiTypeId;
    dataDV.PortSubTypeId = portSubTypeId;
    dataDV.MeasurementInstanceId1 = measurementInstanceId;
    if (measurementDataSource) {
      dataDV.SystemId = measurementDataSource.SystemCpId ? measurementDataSource.SystemCpId.toString() : '';
      dataDV.AssetId = measurementDataSource.AssetCpId ? measurementDataSource.AssetCpId.toString() : '';
      dataDV.AssetName = measurementDataSource.AssetName ? measurementDataSource.AssetName : '';
      dataDV.SystemName = measurementDataSource.SystemName ? measurementDataSource.SystemName : '';
    }
    this.localCacheService.storeData('navigateDV', JSON.stringify(dataDV));
    window.open('#/navigate-data-viz');
  }

  navigateDataViz(measurementObj) {
    const selectedSiteId = JSON.parse(this.localCacheService.fetchData('EdataSelectedSite')).ContextPointId;
    if (this.isDVAccess) {
      const measurementInstanceId = measurementObj && measurementObj.MeasurementInstanceLite && measurementObj.MeasurementInstanceLite.MeasurementInstanceId ? measurementObj.MeasurementInstanceLite.MeasurementInstanceId.toString() : '';
      if (!this.DataSourceDetails[selectedSiteId]) {
        this.getDataSourceDetails(selectedSiteId).then((val) => {
          if (val) {
            const measurementDataSource: any = this.DataSourceDetails[selectedSiteId].find((asset: any) => {
                return asset.ToMeasurementInstanceId ? asset.ToMeasurementInstanceId == measurementInstanceId : asset.MeasurementInstanceId == measurementInstanceId}
                );
            this.updateDVUrlAndNavigate(measurementObj, measurementDataSource,measurementInstanceId);
          }
        })
      } else {
        const measurementDataSource: any = this.DataSourceDetails[selectedSiteId].find((asset: any) => {
          return asset.ToMeasurementInstanceId ? asset.ToMeasurementInstanceId == measurementInstanceId : asset.MeasurementInstanceId == measurementInstanceId}
          );
        this.updateDVUrlAndNavigate(measurementObj, measurementDataSource,measurementInstanceId);
      }
    }
  }

  openCommentModal(value: string, measurementDate) {
    this.headerMeasurementDate = measurementDate;
    if (value === 'ADD') {
      this.commentPopupWindow = this.modalService.open(this.commentPopupValue, { backdrop: 'static', keyboard: false, windowClass: 'addcommentDialog' });
      this.isButtonDisabled = true;
      this.setEditComment = false;
      this.form.reset();
    }
    if (value === 'EDIT') {
      this.form.reset();
      this.dateLoader = false;
      this.setEditComment = true;
      this.isButtonDisabled = false;
      this.commentPopupWindow = this.modalService.open(this.commentPopupValue, { backdrop: 'static', keyboard: false, windowClass: 'addcommentDialog' });
      this.dataSetAnnotations = JSON.parse(this.dataSetAnnotations);
      this.dataSetAnnotations.forEach((val) => {
        let ele = val.ContextPointId
        this.form.patchValue({
          [ele]: val.Annotation
        }
        )
      })
    }
  }

  closeCommentModal(form?) {
    if (this.setEditComment == true && form !== undefined) {
      this.formEditClose(form)
    }
    else {
      this.commentPopupWindow.close();
      this.isButtonDisabled = true;
    }

  }

  closeCommentPopup() {
    this.commentPopupWindow.close();
    this.confirmModal = false;
  }

  commentSuccessModal() {
    this.commentSuccessWindow = this.modalService.open(this.commentSuccessModalValue, { windowClass: 'commentsuccessDialog', backdrop: 'static'});
    this.successCommentModalOpened = true;
  }

  closeCommentSuccessModal() {
    this.commentSuccessWindow.close();
  }

  openSavedEntryModal() {
    this.savedDataEntryWindow = this.modalService.open(this.savedEntryModalValue, { windowClass: 'commentsuccessDialog', backdrop: 'static'});
    this.dataEntrySavedModalOpened = true;
    this.calculatedMeasuremtmnts = [];
  }

  closeSavedEntryModal() {
    this.savedDataEntryWindow.close();
    if (this.dataEntrySavedModalOpened) {
      this.dateLoader = true;
      this.getConfigurationSetup(this.subConfigId, this.defaultPageSize, '', '', false);
      this.value = new Date();
      this.maxDate = new Date();
    }
  }
  openConfirmModal() {
    this.confirmModal = true;
    this.confirmModalWindow = this.modalService.open(this.confirmModalValue, { windowClass: 'confirmDialog', backdrop: 'static', keyboard: false });
  }


  closeConfirmModal() {
    if (this.measurementCommentUpdate) {
      this.confirmModalWindow.close();
      this.modalReference.close();
    }
    else {
      this.confirmModalWindow.close();
      this.commentPopupWindow.close();
    }
  }

  createFormGroup(samplePointArray: any[]): FormGroup {
    const Inputform = new FormGroup({})
    samplePointArray.forEach(x => {
      Inputform.addControl(x.id, new FormControl())
    })
    this.SamplePointComment = samplePointArray;
    return Inputform;
  }

  getControl(key: string) {
    return this.form.get(key) as FormControl
  }

  submitForm(form) {
    let dataSetAnnotationTemp = [];
    let dataSetCommentsTemp = [];
    this.commentLoader = true;
    let dataSetComments = [];
    this.measurementCommentUpdate = false;
    dataSetComments = [...this.SamplePointComment];
    let obj = form.value;
    dataSetComments.forEach((val) => {
      obj[val.id] = obj[val.id] != null ? obj[val.id].trim() : null
      val['value'] = obj[val.id]
      let editedAnnotations = {
        "id": val.id,
        "Annotation": val.value
      }
      dataSetCommentsTemp.push(editedAnnotations);
    });
    dataSetCommentsTemp = dataSetCommentsTemp.filter((val) => (val.Annotation !== null));
    dataSetComments = dataSetComments.filter((val) => (val.value !== null));
    this.dataSetAnnotations.forEach((ele) => {
      ele.Annotation = ele.Annotation != null ? ele.Annotation.trim() : null;
      let oldAnnotations = {
        "id": ele.ContextPointId.toString(),
        "Annotation": ele.Annotation
      }
      dataSetAnnotationTemp.push(oldAnnotations);
    });
    if (this.setEditComment) {
      // edit comments
      dataSetCommentsTemp = dataSetCommentsTemp.sort((a, b) => +a.id - +b.id);
      dataSetAnnotationTemp = dataSetAnnotationTemp.sort((a, b) => +a.id - +b.id);
      if (JSON.stringify(dataSetCommentsTemp) === JSON.stringify(dataSetAnnotationTemp)) {
        this.commentLoader = false;
        this.closeCommentModal();
      } else {
        this.saveEditedForm(dataSetComments);
      }
    }
    else {
      let data: any = [];
      // add comments
      dataSetComments.forEach((item) => {
        if (item.type == 'general') {
          data.push({
            ContextPointId: JSON.parse(item.id),
            AnnotationType: 2,
            Annotation: item.value ? (item.value).trim() : null,
            MeasurementDateTime: this.headerMeasurementDate
          })
        }
        else {
          data.push({
            ContextPointId: JSON.parse(item.id),
            AnnotationType: 1,
            Annotation: item.value ? (item.value).trim() : null,
            MeasurementDateTime: this.headerMeasurementDate
          });
        }
      })
      this.subscriptions.push(this.edataService.saveContextPointAnnotation(data).subscribe(res => {
        this.commentLoader = false;
        if (res && res != null) {
          this.closeCommentModal();
          this.commentSuccessModal();
          // save the added commments locally while adding comments
          let index = this.headers.findIndex((x, i) => {
            return i == 1 ? x.date === this.headerMeasurementDate
              : moment(x.date).format(DATE_FORMAT.DATETIMEFORMAT) === this.headerMeasurementDate
          })
          let counter = 0;
          data.forEach(value => {
            if (value.Annotation != null) {
              counter++;
            }
          })
          if (index != -1) {
            this.headers[index].dataSetComments = counter == 0 ? false : true;
            this.headers[index].annotations = data;
            this.Headers.emit(this.headers);
          }
          if (index == 1 && this.headers[index].userStatus == USER_STATUS.NEW) {
            this.disableCompleteEntryBtn = false;
          }
        }
      },
        error => {
          this.commentLoader = false;
          if (500 <= error.status && error.status < 600) {
          } else if (error.status === 417) {
            this.router.navigate(['/access-denied']);
          } else if (error.status === 404) {
            this.router.navigate(['/**']);
          } else {
            this.errorHandling.showErrorPopup(error);
          }
          this.setState(ComponentState.error);
        }
      ))
    }
  }

  commentValue(ev) {
    if (this.setEditComment) {
      this.isButtonDisabled = false;
    } else {
      let valPresent = Object.keys(this.form.value).some(k => !!this.form.value[k]);
      this.isButtonDisabled = valPresent ? false : true
    }
  }

  // numeric value validation for integers and positive & negative decimal values.
  keyPress(event, data) {
    let valueType = data.MeasurementInstanceLite.Measurement.NalcoNumericsUnitOrSpecies;
    if (valueType.toLowerCase() != "string") {
      var val = (document.getElementById('input' + data.MeasurementInstanceLite.MeasurementInstanceId) as HTMLInputElement
      ).value;
      // to check if the value entered in input box includes in this regex.
      var re = new RegExp(/[0-9\.\- ]/);
      // to check if the whole value entered falls under this pattern.
      var pattern = new RegExp(/^-?[0-9]?\d*(\.\d+)?$/);
      let inputChar = String.fromCharCode(event.charCode);
      // to get the updated value and to check the minus sign validation.
      if (inputChar == "-") {
        (val.indexOf("-") > -1) ? event.preventDefault() : (val = inputChar + val);
      } else {
        val = val + inputChar;
      }
      if ((event.keyCode !== 8 && !re.test(inputChar)) || event.keyCode === 32) {
        event.preventDefault();
      }

      if (val.length > 0) {
        if (val.charAt(val.length - 1) == ".") {
          val = val + "0";
        }
      }

      if (val.length > 1) {
        if (!pattern.test(val)) {
          event.preventDefault();
        }
      }
    }
  }

  editComment(measurementDate, annotation) {
    this.dateLoader = true;
    let data = [];
    this.setEditComment = true;
    annotation.forEach((ele) => {
      data.push({
        ContextPointId: ele.ContextPointId,
        AnnotationType: ele.AnnotationType
      })
    })
    this.subscriptions.push(this.edataService.getContextPointAnnotations(measurementDate, data).subscribe(res => {
      if (res) {
        let result = res;
        this.dataSetAnnotations = JSON.stringify(result);
        this.openCommentModal('EDIT', measurementDate);
      }
    },
      error => {
        if (500 <= error.status && error.status < 600) {
        } else if (error.status === 417) {
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.errorHandling.showErrorPopup(error);
        }
        this.setState(ComponentState.error);

      }
    ));
  }

  formEditClose(form) {
    let dataSetAnnotationTemp = [];
    let dataSetCommentsTemp = [];
    let dataSetComments = [];
    dataSetComments = [...this.SamplePointComment];
    let obj = form.value;
    dataSetComments.forEach((val) => {
      obj[val.id] = obj[val.id] != null ? obj[val.id].trim() : null
      val['value'] = obj[val.id]
      let editedAnnotations = {
        "id": val.id,
        "Annotation": val.value
      }
      dataSetCommentsTemp.push(editedAnnotations);
    });
    dataSetCommentsTemp = dataSetCommentsTemp.filter((val) => (val.Annotation !== null));
    dataSetComments = dataSetComments.filter((val) => (val.value !== null));
    this.dataSetAnnotations.forEach((ele) => {
      ele.Annotation = ele.Annotation != null ? ele.Annotation.trim() : null;
      let oldAnnotations = {
        "id": ele.ContextPointId.toString(),
        "Annotation": ele.Annotation
      }
      dataSetAnnotationTemp.push(oldAnnotations);
    });
    if (this.setEditComment) {
      dataSetCommentsTemp = dataSetCommentsTemp.sort((a, b) => +a.id - +b.id);
      dataSetAnnotationTemp = dataSetAnnotationTemp.sort((a, b) => +a.id - +b.id);
      if (JSON.stringify(dataSetCommentsTemp) === JSON.stringify(dataSetAnnotationTemp)) {
        this.closeCommentModal();
      } else {
        let index = dataSetCommentsTemp.findIndex(x => x.Annotation != '');
        index == -1 ? this.closeCommentPopup() : this.openConfirmModal();
      }
    }
  }

  saveEditedForm(dataSetComments) {
    // add annotation id if annotation was updated.
    dataSetComments.forEach((ele) => {
      let index = this.dataSetAnnotations.findIndex(x => JSON.stringify(x.ContextPointId) == ele.id);
      ele.updated == true ? ele.updated = false : '';
      ele.newFields == true ? ele.newFields = false : '';
      if (index != -1) {
        if (ele.value !== this.dataSetAnnotations[index].Annotation) {
          ele.updated = true;
          ele.annotationId = this.dataSetAnnotations[index].ContextPointAnnotationId;
        }
      }
      else {
        ele.newFields = true;
      }
    })

    // send empty string if the annotation was updated as empty
    this.dataSetAnnotations.forEach((ele) => {
      let index = dataSetComments.findIndex(x => x.id == JSON.stringify(ele.ContextPointId));
      if (index == -1) {
        dataSetComments.push({
          id: JSON.stringify(ele.ContextPointId), value: '',
          type: ele.AnnotationType == 2 ? 'general' : 'samplePoint', updated: true, annotationId: ele.ContextPointAnnotationId
        })
      }
    })
    let editdata = [];
    dataSetComments.forEach((item) => {
      if (item.updated) {
        if (item.type == 'general') {
          editdata.push({
            ContextPointId: JSON.parse(item.id),
            AnnotationType: 2,
            Annotation: item.value ? (item.value).trim() : null,
            MeasurementDateTime: this.headerMeasurementDate,
            ContextPointAnnotationId: item.annotationId
          })
        }
        else {
          editdata.push({
            ContextPointId: JSON.parse(item.id),
            AnnotationType: 1,
            Annotation: item.value ? (item.value).trim() : null,
            MeasurementDateTime: this.headerMeasurementDate,
            ContextPointAnnotationId: item.annotationId
          });
        }
      }

      if (item.newFields) {
        if (item.type == 'general') {
          editdata.push({
            ContextPointId: JSON.parse(item.id),
            AnnotationType: 2,
            Annotation: item.value ? (item.value).trim() : null,
            MeasurementDateTime: this.headerMeasurementDate,
          })
        }
        else {
          editdata.push({
            ContextPointId: JSON.parse(item.id),
            AnnotationType: 1,
            Annotation: item.value ? (item.value).trim() : null,
            MeasurementDateTime: this.headerMeasurementDate,
          });
        }
      }
    })
    if (editdata.length > 0) {
      this.subscriptions.push(this.edataService.saveContextPointAnnotation(editdata).subscribe(res => {
        this.commentLoader = false;
        if (res && res != null) {
          if (this.confirmModal) {
            this.closeConfirmModal();
          }
          this.confirmModal = false;
          this.closeCommentModal();
          this.commentSuccessModal();
          // storing the added data locally while editing comments
          let index = this.headers.findIndex((x, i) => {
            return i == 1 ? x.date === this.headerMeasurementDate
              : moment(x.date).format(DATE_FORMAT.DATETIMEFORMAT) === this.headerMeasurementDate
          })
          let counter = 0;
          dataSetComments.forEach(item => {
            if (item.value != "") {
              counter++;
            }
          })
          if (index != -1) {
            this.headers[index].dataSetComments = counter == 0 ? false : true;
            this.Headers.emit(this.headers);
            editdata.forEach((val) => {
              if (val.ContextPointAnnotationId) {
                let found = this.headers[index].annotations.findIndex(x => x.ContextPointAnnotationId == val.ContextPointAnnotationId)
                if (found != -1) {
                  let element = this.headers[index];
                  element.annotations[found] = val;
                  element.annotationEdited = counter == 0 ? false : true;
                }
              }
              else {
                this.headers[index].annotations.push(...editdata);
                this.headers[index].annotationEdited = true;
                this.Headers.emit(this.headers);
              }
            })
          }
          editdata = [];
          this.dataSetAnnotations = [];
          dataSetComments = [];
        }
      },
        error => {
          this.commentLoader = false;
          if (500 <= error.status && error.status < 600) {
          } else if (error.status === 417) {
            this.router.navigate(['/access-denied']);
          } else if (error.status === 404) {
            this.router.navigate(['/**']);
          } else {
            this.errorHandling.showErrorPopup(error);
          }
          this.setState(ComponentState.error);
        }
      ))
    }
    else {
      this.closeCommentModal();
    }

  }

  exitUpdatedComments() {
    this.confirmModalWindow.close();
  }

  keyUp(data) {
    var val = (document.getElementById('input' + data.MeasurementInstanceLite.MeasurementInstanceId) as HTMLInputElement
    ).value;
    let getexistingminusIndex = val.indexOf("-");
    if (getexistingminusIndex > 0) {
      let validnumber = val.slice(0, val.length - 1);
      (document.getElementById('input' + data.MeasurementInstanceLite.MeasurementInstanceId) as HTMLInputElement
      ).value = validnumber;
    }
  }

  completeEntry() {
    this.calulatedVariableList = [];
    this.storePreviousSite = false;
    this.subConfigurationCount = 0;
    this.subConfigNavigation.emit(this.subConfigurationCount);
    if (this.failedReadings.length > 0) {
      this.openRetryPopUp();
    }
    else if (this.savedReadingsCopy.length == 0 && !this.headers[1].dataSetComments && !this.measurementCommentAdded) {
      // this.dateLoader = true;
      this.dataSubmitWithoutReadings = true;
      this.getConfigurationSetup(this.subConfigId, this.defaultPageSize, '', '');
    }
    else {
      this.openSavedEntryModal();
    }
  }

  // to open retry Pop up.
  openRetryPopUp() {
    const modalRef = this.modalService.open(EDataEntryModalsComponent, {
      backdrop: 'static',
      keyboard: false,
      windowClass: 'retry-pop-up'
    });
    modalRef.componentInstance.modalText = MODALS.RETRY_SAVE_READINGS;
    modalRef.componentInstance.emitRetryResponse.subscribe((res) => {
      if (res == "submitWithoutUnsavedData") {
        this.openSavedEntryModal();
      } else if (res == "retry") {
        if (this.failedReadings.length > 0) {
          this.failedReadings.forEach(val => {
            this.saveHistoryData(val.inputValue, val.row, val.savedData, val.valueType, val.tempData, true);
          });
          this.failedReadingsData.emit(this.failedReadings);
        } else {
          this.openSavedEntryModal();
        }
      }
    });
  }

  openMeasurementComment(contextPoint, measurementDetails, measurementLimit,
    row, column, actionType, dataCaptureUnit) {
    this.measurementCommentUpdate = false;
    let inputVal: any;
    const modalRef = this.modalService.open(EDataEntryModalsComponent, {
      backdrop: 'static',
      keyboard: false,
      windowClass: 'measurementComment-Modal'
    });
    this.modalReference = modalRef
    modalRef.componentInstance.modalText = MODALS.MEASUREMENT_COMMENTS;
    if (row.MeasurementInstanceLite.Measurement.OptionGroupId != null && row.MeasurementOptionDetails.length != 0) {
      let valueId = (document.getElementById('input' + row.MeasurementInstanceLite.MeasurementInstanceId) as HTMLInputElement).value;
      let optionIndex = row.MeasurementOptionDetails.filter(ele => {
        return ele.OptionId == valueId
      }
      )
      if (optionIndex.length > 0) {
        inputVal = optionIndex[0].OptionName;
      }
      else {
        inputVal = '';
      }
    }
    else {
      inputVal = (document.getElementById('input' + row.MeasurementInstanceLite.MeasurementInstanceId) as HTMLInputElement).value;
    }
    modalRef.componentInstance.displayData = {
      'contextPoint': contextPoint, 'measurementDetails': measurementDetails,
      'measurementLimit': measurementLimit, 'date': column.date, 'measurementCellValue': inputVal,
      'dataCaptureUnit': dataCaptureUnit != null ? dataCaptureUnit : null
    }
    if (actionType == 'ADD') {
      modalRef.componentInstance.editData = '';
      modalRef.componentInstance.emitResponse.subscribe((res) => {
        if (res && res.result == true) {
          this.commentSuccessModal();
          this.commentAdded(row, column, res);
        }
        if (res.result == false || res.result == null || res.result.error) {
          this.commentAdded(row, column, res);
        }
      });
    }
    if (actionType == 'EDIT') {
      modalRef.componentInstance.editData = MODALS.EDIT_COMMENTS;
      modalRef.componentInstance.emitResponse.subscribe((res) => {
        if (res && res.result == true) {
          if (res.data.Annotation == null) {
            row.measurementComments = false;
            this.measurementCommentAdded = false
          };
          this.commentSuccessModal();
        }
        if (res && res.result == 'commentUpdated') {
          this.measurementCommentUpdate = true;
          this.openConfirmModal();
        }
      });
    }
  }

  commentAdded(row, column, res) {
    if (column.name !== 'name') {
      let userData = this.historicalData.filter(data => (data.MeasurementDate == column.date));
      let tempData = userData[0].MeasurementInstanceReadings.filter(x => x.MeasurementInstanceLite.MeasurementInstanceId == row.MeasurementInstanceLite.MeasurementInstanceId);
      if (tempData.length == 0) {
        return '';
      }
      else {
        tempData[0].MeasurementInstanceAnnotation = res.data;
        res.result == true ? (row.measurementComments = true, this.measurementCommentAdded = true) : (row.measurementComments = 'failed', this.measurementCommentFailed = true);
        //  res.result == true && res.data.Annotation == null? (row.measurementComments = false, this.measurementCommentAdded = false) : res.result == true && res.data.Annotation != null? (row.measurementComments = true, this.measurementCommentAdded = true) : (row.measurementComments = 'failed', this.measurementCommentFailed = true);
        row.MeasurementInstanceAnnotation = res.data;
        this.disableCompleteEntryBtn = false;
        return row.measurementComments;
      }
    }
  }

  showPopup(msrInstanceConfig, row) {

    //get measurementInstanceCalculationMaps,measurementInstanceConfigurations
    // and measurementInstanceId objects for selected row
    let selectedCalcMsr = this.calculatedMeasuremtmnts.find(msr => msr.measurementInstanceId == msrInstanceConfig.MeasurementInstanceLite.MeasurementInstanceId)

    //create new model for popup data
    let calcMsrPopupModel = {
      samplePoint: row.ContextPointLite.ContextPointName,
      calculationName: selectedCalcMsr.measurementInstanceConfigurations.MeasurementInstanceLite.DisplayName,
      formula: selectedCalcMsr.measurementInstanceConfigurations.CalculationConfiguration.Formula,
      gridData: [],
      samplePointRow: row.ContextPointLite
    }
    const modalRef = this.modalService.open(EDataEntryModalsComponent, {
      backdrop: 'static',
      keyboard: false,
      windowClass: 'confirmDialog'
    }).componentInstance;
    modalRef.modalText = MODALS.CALCULATED_MSR_POPUP;
    modalRef.showLoader = true;
    modalRef.selectedCalcMsrDetails = calcMsrPopupModel;

    //assign msrName and samplePoint in grid
    selectedCalcMsr.measurementInstanceCalculationMaps.forEach((calcMap, i) => {
      let msrName = '-', samplePoint = '-', desc = '-', selectedInputGroups = [], UoM = '-';

      this.rows.forEach(row => {
        row.MeasurementInstanceConfigurations.forEach(msrInstance => {
          //search FromMeasurementInstanceId across all MeasurementInstanceConfigurations objects
          if (msrInstance.MeasurementInstanceLite.MeasurementInstanceId == calcMap.FromMeasurementInstanceId) {
            msrName = msrInstance.MeasurementInstanceLite.DisplayName;
            if (msrInstance.DataCaptureDimensionalSubUnit && msrInstance.DataCaptureDimensionalSubUnit.Symbol)
              UoM = msrInstance.DataCaptureDimensionalSubUnit.Symbol
          }
        })
        //selected row's cpname from cplite object is sample point mapped in grid
        if (msrName != '-' && samplePoint == '-') {
          samplePoint = row.ContextPointLite.ParentName ? (row.ContextPointLite.ParentName + ' | ' + row.ContextPointLite.ContextPointName) : row.ContextPointLite.ContextPointName;
        }
      })
      //map description from InputGroups
      selectedInputGroups = selectedCalcMsr.measurementInstanceConfigurations.CalculationConfiguration.InputGroups;
      if (selectedInputGroups && selectedInputGroups.length != 0) {
        desc = selectedInputGroups.find(ig => ig.DependentMeasurementInfo.VariableName == calcMap.VariableName).DependentMeasurementInfo.Description
      }

      //create grid model
      let newGridModel = {
        variable: calcMap.VariableName ? calcMap.VariableName : '-',
        desc: desc,
        samplePoint: samplePoint,
        msrName: msrName,
        UoM: UoM
      }
      calcMsrPopupModel.gridData.push(newGridModel);

      if (selectedCalcMsr.measurementInstanceCalculationMaps.length - 1 == i) {
        modalRef.showLoader = false;
      }


    });
  }

  editColumn(column) {
    this.calulatedVariableList = [];
    this.savedReadingsCopy = [];
    this.savedReadingsCopyData.emit(this.savedReadingsCopy);
    this.failedReadings = [];
    this.failedReadingsData.emit(this.failedReadings);
    this.measurementCommentAdded = false;
    this.measurementCommentFailed = false;
    // On edit, Complete entry button is enabled in all case.
    this.disableCompleteEntryBtn = false;
    this.onEditMode = true;
    this.disableBtn = true;
    this.showDatepickerValue = false;
    this.disableEditBtn = true;
    this.deletedDataset = false;
    this.headers.forEach(data => {
      if (data.date == column.date) {
        data.userStatus = USER_STATUS.EDIT
      }
    })
    this.Headers.emit(this.headers);
    // to add MeasurementInstanceReadings in the edited column to preserve the values while collapsing and expanding.
    this.historicalData.forEach(value => {
      if (value.MeasurementDate == column.date) {
        if (this.mapping.length > 0) {
          this.mapping.forEach(el => {
            let userData = {
              MeasurementInstanceAnnotation: null,
              MeasurementValue: new MeasurementValue(),
              MeasurementInstanceLite: new MeasurementInstanceLite(),
              User: new User()
            };
            userData.MeasurementInstanceLite.MeasurementInstanceId = el.MeasurementInstanceId;
            userData.User.FirstName = this.localCacheService.fetchData('User_FirstName', 'local');
            userData.User.LastName = this.localCacheService.fetchData('User_LastName', 'local');
            let index = value.MeasurementInstanceReadings.findIndex(x => x.MeasurementInstanceLite.MeasurementInstanceId === el.MeasurementInstanceId);
            if (index == -1) {
              value.MeasurementInstanceReadings.push(
                userData
              )
            } else {
              // need to check it for the case when only measurement level comments present but reading is null.
              if (value.MeasurementInstanceReadings[index].MeasurementValue == null) {
                value.MeasurementInstanceReadings[index].MeasurementValue = new MeasurementValue();
              }
            }
          });
        }
      }
    })

  }

  deleteDataset(column) {
    let dataPresent = false;
    const modalRef = this.modalService.open(EDataEntryModalsComponent, {
      backdrop: 'static',
      keyboard: false,
      windowClass: 'confirmDialog Delete'
    });
    if (column.userStatus == "new" && (this.savedReadingsCopy.length > 0 || this.failedReadings.length > 0
      || this.headers[1].dataSetComments || this.measurementCommentAdded || this.measurementCommentFailed)) {
      dataPresent = true;
    }
    else {
      dataPresent = false;
    }
    modalRef.componentInstance.modalText = MODALS.DELETE_DATASET_CONFIRMATION;
    modalRef.componentInstance.deleteData = {
      configId: this.subConfigId,
      measurementDate: column.date, column: column, dataPresent
    }
    modalRef.componentInstance.deleteAction.subscribe((res) => {
      if (res && res.result == true || res.result == 'newSetWithoutData') {
        this.deletedDataset = true;
        this.openSavedEntryModal();
      }
      else {
        this.deletedDataset = false;
      }
    })
  }

  checkReadOnly(row, column) {
    let attributes = row.Attributes;
    let index: any;
    if (attributes != null && attributes.length > 0) {
      index = attributes.findIndex(ele => (ele.AttributeType == this.rapidBio.READ_ONLY && ele.AttributeValue == 'true'))
    }
    if (column.name !== 'name') {
      let userData = this.historicalData.filter(data => (data.MeasurementDate == column.date));
      if (userData.length > 0) {
        let tempData = userData[0].MeasurementInstanceReadings.filter(x => x.MeasurementInstanceLite.MeasurementInstanceId == row.MeasurementInstanceLite.MeasurementInstanceId);
        if (tempData.length == 0) {
          return '';
        }
        else {
          if (index != undefined || row.MeasurementInstanceLite.OriginType == this.rapidBio.CAL_MEASUREMENT_ORIGIN_TYPE) {
            return true;
          } else
            return false;
        }
      }
    }
  }

  measurementOptionSelected(ev, optionList, column, row, cpid) {
    let selectedMeasurementId: any;
    let option: any;
    if (ev.target.value == undefined || ev.target.value == '') {
      selectedMeasurementId = '';
      this.valueChange('', selectedMeasurementId, column, row, cpid, 'Select');
    }
    else {
      selectedMeasurementId = ev.target.value;
      option = optionList.filter(ele => { return ele.OptionId == selectedMeasurementId })
      this.measurementOptionName = option[0].OptionName;
      this.valueChange('', selectedMeasurementId, column, row, cpid, option[0].OptionName);
    }
  }

  limitCheck(row, column) {
    let inputValue: any;
    if (column.name !== 'name') {
      let userData = this.historicalData.filter(data => (data.MeasurementDate == column.date));
      if (userData.length > 0) {
        let tempData = userData[0].MeasurementInstanceReadings.filter(x => x.MeasurementInstanceLite.MeasurementInstanceId == row.MeasurementInstanceLite.MeasurementInstanceId);
        if (tempData.length == 0) {
          return '';
        }
        else {
          if (tempData[0] && tempData[0].MeasurementValue && tempData[0].MeasurementValue.v != null) {
            inputValue = tempData[0].MeasurementValue.v
          }
          if (inputValue === '' || row.MeasurementLimit.limit_status == this.limitsStatus.NO_LIMIT
           || inputValue == undefined || row.isInfinity == true) {
            return '';
          }
          else {
            // when only Low and High limits are present
            if (row.MeasurementLimit.LowLowLimit == null && row.MeasurementLimit.HighHighLimit == null
              && row.MeasurementLimit.LowerLimit != null && row.MeasurementLimit.HigherLimit != null) {
              if (inputValue < row.MeasurementLimit.LowerLimit || inputValue > row.MeasurementLimit.HigherLimit) {
                row.MeasurementInstanceLite.columnDate = column.date;
                return 'cell_exceedLLHH';
              }
            }
            // when only HighHighlimit are not present
            else if (row.MeasurementLimit.LowLowLimit != null && row.MeasurementLimit.LowerLimit != null &&
              row.MeasurementLimit.HigherLimit != null && row.MeasurementLimit.HighHighLimit == null) {
              if (inputValue < row.MeasurementLimit.LowerLimit || inputValue > row.MeasurementLimit.HigherLimit) {
                row.MeasurementInstanceLite.columnDate = column.date;
                return 'cell_exceedLLHH';
              }
            }
            // when only LowLowLimit are not present
            else if (row.MeasurementLimit.LowerLimit != null && row.MeasurementLimit.HigherLimit != null &&
              row.MeasurementLimit.HighHighLimit != null && row.MeasurementLimit.LowLowLimit == null) {
              if (inputValue < row.MeasurementLimit.LowerLimit || inputValue > row.MeasurementLimit.HigherLimit) {
                row.MeasurementInstanceLite.columnDate = column.date;
                return 'cell_exceedLLHH';
              }
            }
            // when HigherLimit &&  HighHighLimit are not present
            else if (row.MeasurementLimit.LowLowLimit != null && row.MeasurementLimit.LowerLimit != null
              && row.MeasurementLimit.HigherLimit == null && row.MeasurementLimit.HighHighLimit == null) {
              if (inputValue < row.MeasurementLimit.LowerLimit) {
                row.MeasurementInstanceLite.columnDate = column.date;
                return 'cell_exceedLLHH';

              }
            }

            // when LowLowLimit &&  LowerLimit are not present
            else if (row.MeasurementLimit.HigherLimit != null && row.MeasurementLimit.HighHighLimit != null
              && row.MeasurementLimit.LowLowLimit == null && row.MeasurementLimit.LowerLimit == null) {
              if (inputValue > row.MeasurementLimit.HigherLimit) {
                row.MeasurementInstanceLite.columnDate = column.date;
                return 'cell_exceedLLHH';
              }
            }
            // when only LowerLimit is present
            else if (row.MeasurementLimit.LowLowLimit == null && row.MeasurementLimit.HighHighLimit == null &&
              row.MeasurementLimit.LowerLimit != null && row.MeasurementLimit.HigherLimit == null) {
              if (inputValue < row.MeasurementLimit.LowerLimit) {
                row.MeasurementInstanceLite.columnDate = column.date;
                return 'cell_exceedLLHH';
              }
            }

            // when only HigherLimit is present
            else if (row.MeasurementLimit.LowLowLimit == null && row.MeasurementLimit.HighHighLimit == null &&
              row.MeasurementLimit.LowerLimit == null && row.MeasurementLimit.HigherLimit != null) {
              if (inputValue > row.MeasurementLimit.HigherLimit) {
                row.MeasurementInstanceLite.columnDate = column.date;
                return 'cell_exceedLLHH';
              }
            }

            // when all limits are present
            else if (row.MeasurementLimit.LowLowLimit != null && row.MeasurementLimit.HighHighLimit != null &&
              row.MeasurementLimit.LowerLimit != null && row.MeasurementLimit.HigherLimit != null) {
              if (inputValue < row.MeasurementLimit.LowerLimit || inputValue > row.MeasurementLimit.HigherLimit) {
                row.MeasurementInstanceLite.columnDate = column.date;
                return 'cell_exceedLLHH';
              }
              else {
                return ''
              }
            }
          }
        }
      }
    }
  }

  fixedColumn(column) {
    return column.field === 'samplename' ? 'fixed-cloumn' : '';
  }

  greenColumn(column) {
    return (column.userStatus === 'new' || column.userStatus === 'edit') ? 'green-col' : '';
  }

  rowHighlight(row, j) {
    return (this.samplepointIdVal === row.ContextPointLite.ContextPointId && this.measurementIndexVal == j) ? 'row-highlight' : '';
  }

  getCalculatedMeasurementInput(column, calcMeasurementId) {
    let getIndex = this.historicalData.findIndex(value => value.MeasurementDate == column.date);
    if (getIndex != -1) {
      let measurementInstanceReadings = this.historicalData[getIndex].MeasurementInstanceReadings;
      if (measurementInstanceReadings.length > 0) {
        let data = measurementInstanceReadings.find(val => val.MeasurementInstanceLite.MeasurementInstanceId == calcMeasurementId);
        return data != null ? data.MeasurementValue.v : ''
      }
      else {
        return ''
      }
    }
  }

  markasInfinity(resultId, infinityCase) {
    this.rows.forEach((ele) => {
      if (ele.MeasurementInstanceConfigurations.length > 0) {
        ele.MeasurementInstanceConfigurations.forEach((data) => {
          if (data.MeasurementInstanceLite.MeasurementInstanceId == resultId) {
            if (infinityCase == false && data['isInfinity'] == true || infinityCase == 'restoreCell') {
              data['isInfinity'] = false;
            }
            else if (infinityCase == true) {
              data['isInfinity'] = true;
            }
          }
        })
      }
    })
  }

  dataSequenceandSort() {
    return this.globalArr.forEach((ele) => {
      ele.MeasurementInstanceConfigurations.forEach((y) => {
        this.mapping.filter((x) => {
          if (y.MeasurementInstanceLite.MeasurementInstanceId == x.MeasurementInstanceId) {
            y.sequenceNo = x.SequenceNumber;
          }
        })
        ele.MeasurementInstanceConfigurations = ele.MeasurementInstanceConfigurations.sort((a, b) => +a.sequenceNo - +b.sequenceNo);
      })
    })
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
    document.removeEventListener('visibilitychange', this.visibilityChangeListener);
  }

}
