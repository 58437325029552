<div class="inventory-list-modal">
   <form [formGroup]="inventoryDetailsForm" #f="ngForm" >
      <div class="modal-header">
         <h3 class="modal-title">{{'SS_DASHBOARD.CUSTOMIZE_INVENTORY_TABLE.CUSTOMIZE_INVENTORY_TABLE'| translate}}</h3>
         <button type="button" class="close" aria-label="Close" (click)="dimissModal()">
            <span class="ico-exit-round-edge"></span>
         </button>
      </div>
      <div class="modal-header">
         <h3>{{'SS_DASHBOARD.CUSTOMIZE_INVENTORY_TABLE.SELECT_TANK_MSG'| translate}}</h3>
      </div>
      <nalco-tile [parentInstance]="myParentInstance()"   [showMoreOptions]=false onclick="void(0)" [showExportAsCsv]=false [showInfoIcon]=false
         [headerTitle]="" class="no-header main-tabs">
         <div tile-content *ngIf="masterTanksData && masterTanksData.length > 0">
            <div class="modal-body">
               <ngb-tabset #tabset="ngbTabset" class="col-arrow-top site-nav tabs tco-tabs" [activeId]="activeTab">
                 <ngb-tab ngbTabTitle title="{{'SS_DASHBOARD.CUSTOMIZE_INVENTORY_TABLE.COLUMNS'| translate}}" id="columns" *ngIf="isColumnTabPresent">
                   <ng-template ngbTabContent>
                      <ul class="assets-list">
                         <li class="custom-control custom-checkbox asset-row clearfix"
                            *ngFor="let item of columns; let i = index">
                               <label class="inventory-modal-checkbox">
                                  <input type="checkbox"
                                  [disabled]="item.isLocked" [checked]="item.defaultActive"
                                   [value]="item.title"
                                  (change)="onChangeItemInPopup(item, $event)" />
                                  <span class="checkmark"></span>
                               </label>
                             <label class= "pl-2" for="item.title">{{item.resource_key | translate}}</label>
                         </li>
                     </ul>
                   </ng-template>
                 </ngb-tab>
                 <ngb-tab ngbTabTitle title="{{'SS_DASHBOARD.CUSTOMIZE_INVENTORY_TABLE.TANKS'| translate}}" id="tanks" *ngIf="isTanksTabPresent">
                   <ng-container>
                      <ng-template ngbTabContent>
                         <div class="table">
                            <div class="table-row-header">
                               <div class="table-cell  p-2">
                                  <label class="inventory-modal-checkbox">
                                     <input type="checkbox" formControlName="checkUncheckAll"
                                     (change)="checkUncheckAll(getEventTargetChecked($event))">
                                     <span class="checkmark"></span>
                                  </label>
                               </div>
                               <div class="table-cell p-2 serialNumberHeading">{{'SS_DASHBOARD.CUSTOMIZE_INVENTORY_TABLE.SERIAL_NUMBER'| translate}}</div>
                               <div class="table-cell p-2 productNameHeading">{{'SS_DASHBOARD.CUSTOMIZE_INVENTORY_TABLE.PRODUCT_NAME'| translate}}</div>
                               <div class="table-cell p-2 tankTypeClass">{{'SS_DASHBOARD.CUSTOMIZE_INVENTORY_TABLE.TANK_TYPE'| translate}}</div>
                               <div class="table-cell p-2 dataSourceClass">{{'SS_DASHBOARD.CUSTOMIZE_INVENTORY_TABLE.SHOW_MULTIPLE_DATA_SOURCES'| translate}}</div>
                               <div class="table-cell p-2 slugFeedClass">{{'SS_DASHBOARD.CUSTOMIZE_INVENTORY_TABLE.SLUG_FEED'| translate}}</div>
                               <div class="table-cell p-2 slugClass"># {{'SS_DASHBOARD.CUSTOMIZE_INVENTORY_TABLE.SLUGS'| translate}}</div>
                               <div class="table-cell p-2 targetUsageClass">{{'SS_DASHBOARD.CUSTOMIZE_INVENTORY_TABLE.TARGET_USGAE'| translate}}</div>
                            </div>
                            <div formArrayName="formDetails">
                               <div *ngFor="let dataItem of masterTanksData ; let i=index">
                                  <div [formGroupName]="i" class="table-row-body">
                                     <div class="table-cell p-2">
                                        <label class="inventory-modal-checkbox">
                                           <input type="checkbox" formControlName="tankSelected"
                                              (change)="tankChecked(getEventTargetChecked($event), i, dataItem)">
                                           <span class="checkmark"></span>
                                        </label>
                                     </div>
                                     <!-- Serial Number  -->
                                    <div class="table-cell p-2 serialNumberClass" *ngFor="let item of dataItem.Aliases" [ngbTooltip]="tipContent"
                                       placement="right">
                                       {{(item.Value && item.Value !== 'NULL' && item.Value !== 'null' && item.Value !== null && item.Value !== '') ?
                                       item.Value : '-' }}
                                    </div>
                                    <ng-template #tipContent>
                                       <div *ngFor="let item of dataItem.Aliases">
                                          {{(item.Value && item.Value !== 'NULL' && item.Value !== 'null' && item.Value !== '') ? item.Value : '-' }}
                                       </div>
                                    </ng-template>
                                     <!-- Add Product Name -->
                                     <div class="table-cell p-2 productNameClass" [ngbTooltip]="productNameTooltip" placement="right">
                                        {{(dataItem.ProductNameValue && dataItem.ProductNameValue !== 'NULL' && dataItem.ProductNameValue !== 'null' && dataItem.ProductNameValue !== '' ) ? dataItem.ProductNameValue : '-' }}
                                     </div>
                                     <ng-template #productNameTooltip>{{(dataItem.ProductNameValue && dataItem.ProductNameValue !== 'NULL' && dataItem.ProductNameValue !== 'null' && dataItem.ProductNameValue !== '') ? dataItem.ProductNameValue : '-' }}</ng-template>
                                     <!-- Add Product Name Ends-->
                                    <!-- Multiple DataSourceTypes with Language translation -->
                                    <div class="table-cell p-2 tankTypeClass" *ngIf="dataItem.AssetDataSourceTypes.length === 0"
                                       [ngbTooltip]="tankTypeTooltip" placement="right">-</div>
                                    <div class="table-cell p-2 tankTypeClass" *ngFor="let item of dataItem.AssetDataSourceTypes"
                                       [ngbTooltip]="tankTypeTooltip" placement="right">
                                       <span *ngIf="item && item.resource_key">{{item.resource_key | translate}}</span>
                                    </div>
                                    <ng-template #tankTypeTooltip>
                                       <span *ngIf="dataItem.AssetDataSourceTypes.length === 0">-</span>
                                       <span *ngFor="let item of dataItem.AssetDataSourceTypes">
                                          <span *ngIf="item && item.resource_key">{{item.resource_key | translate}}</span>
                                       </span>
                                    </ng-template>
                                    <div class="table-cell p-2 dataSourceClass">
                                       <label class="inventory-modal-checkbox">
                                          <input type="checkbox" formControlName="dataSource" 
                                           (change)="multipleDataSourceChecked($event, i)">
                                          <span class="checkmark"></span>
                                       </label>
                                    </div>
                                     <div class="table-cell p-2 slugFeedClass">
                                        <label class="inventory-modal-checkbox">
                                           <input type="checkbox" formControlName="slugFeed" 
                                              (change)="slugChecked($event, i)">   
                                           <span class="checkmark"></span>
                                        </label>                                    
                                     </div>
                                     <div class="table-cell p-2 slugClass">
                                        <input type="number" formControlName="noOfSlugs"  class="inputFeild"
                                        (keypress)="onKeyPress($event)"  oncopy="return false" onpaste="return false">                                       
                                     </div>
                                     <div class="table-cell d-inline-flex p-2 targetUsageClass">
                                        <select class="selectDropdown mr-3" formControlName="targetUsageType"
                                              (change)='onTarUsageChange($event, i)'>
                                              <option *ngFor="let item of targetUsageDropDown" value="{{item.id}}">
                                                 {{item.display}}
                                              </option>
                                           </select>
                                           <input type="number" step = 0.01 formControlName="targetUsageValue" class="inputFeild"
                                              (keypress)="keyPress($event)" oncopy="return false" onpaste="return false">                                     
                                     </div>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </ng-template>
                   </ng-container>
                 </ngb-tab>
               </ngb-tabset> 
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary"
                   (click)="dimissModal()">{{'COMMON.CANCEL' | translate}}</button>
                <button type="button" class="btn btn-primary" [disabled]="!inventoryDetailsForm.dirty && !isColsChanged"             
                   (click)="addInventoryData()">{{'COMMON.ADD' | translate}}</button>
            </div>
         </div>
          <div tile-content *ngIf="masterTanksData.length === 0" class="error-state-message">
             <span>{{'COMMON.EMPTY_TITLE_TEXT' | translate }}</span>
          </div>
      </nalco-tile>
   </form>
 </div>