
<div ngbDropdown  #myDropdown= "ngbDropdown" class="dropdown-section" [ngClass]="{'disabled': (isDisabled && isDisabled==true)}" display="static" [autoClose]="false">
  <div class="btn-outline-primary drop-btn" id="dropdownBasic1" ngbDropdownToggle [ngClass]="{'disabled': (isDisabled && isDisabled==true)}">
    <span id="icon" *ngIf="iconClass" [ngClass]="iconClass!=''? iconClass:'' " class="w-20 {{(isDisabled && isDisabled==true) ?'disabled' :''}}" ></span>
    <span  *ngIf="!showSelect" class="label button-label margin-left-5">{{ddData.label}}</span>
    <span *ngIf="showSelect" class="label button-label margin-left-5"><span>{{ddData.label}}</span><span>{{selectedOptionText()}}</span></span>
    <span class="c-three-dots-loader" *ngIf="isDisabled==false && ddData.options.length==0 && selectedOptionText().trim()==''"></span>
  </div>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-list-container">
    <span *ngFor="let option of ddData.options; let i = index" [ngbTooltip]="option?.tooltip">
        <nalco-divider [color]="dividerCss.color"  [length]="dividerCss.maxLength" [isHorizontal]="dividerCss.isHorizontal"
          [customClasses]="transitions-menus" *ngIf="option.text === 'Custom' &&  option.isSelected !== true && isCustomDate">
        </nalco-divider>
      <div *ngIf="isCustomDate" class="custom-date-picker">
        <button class="dropdown-item overflow-ellipsis" placement="bottom" #element
        (mouseenter)="getTooltip(element,i,'text1')"
        [ngClass]="{selected: option.isSelected,'disabled': option.isDisabled}" value="{{option.value}}"
        (click)="changeInDropdown(ddData.name, option.value,option.text)"
        [disabled]="option.isDisabled">{{option.text}}</button>
        <ngb-accordion *ngIf="option.text === 'Custom' && isCustomeOption" #acc="ngbAccordion" class="custom-accordion admin-accordion"
          [activeIds]="activeIds">
          <ngb-panel id="customDate">
          
      
            <ng-template ngbPanelContent>
              <custom-date-picker [bottomView]="'month'" (updateDate)="updateDate($event)"></custom-date-picker>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
     
      </div>
      <button class="dropdown-item overflow-ellipsis" placement="bottom" #element (mouseenter)="getTooltip(element,i,'text1')"
        *ngIf="!isCustomDate" [ngClass]="{selected: option.isSelected,'disabled': option.isDisabled}" value="{{option.value}}"
        (click)="changeInDropdown(ddData.name, option.value,option.text)"
        [disabled]="option.isDisabled">{{option.text}}</button>
    </span>
  </div>
</div>