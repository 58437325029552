<div class="side-panel" *ngIf="sidePanelValue">
    <div class="side-card-header">
        <div class="card-header">
            <span class="card-title">{{headerDetails.headerOptions?.headerText}}</span>
            <span class="close-side-panel float-right" (click)="closeSidePanel()"><img
                    src="./../../../assets/images/filter-icons/close-button.svg"></span>
        </div>

    </div>
    <div class="side-card-body">
        <ng-content select="[side-panel-body]"></ng-content>
    </div>
    <div class="side-card-footer">
        <ng-content select="[side-panel-footer]"></ng-content>
    </div>
</div>